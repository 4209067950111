/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import {
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';

import ComboStationConfigForm from './ComboStationConfigForm';
import { COMBO_STATION_UPDATE_CONFIG_MUTATION } from '../queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

const ComboStationConfigEditModal = (props) => {
  const {
    canEdit,
    comboStation,
    config,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const [
    comboStationConfigUpdateMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(COMBO_STATION_UPDATE_CONFIG_MUTATION);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const handleFormSubmit = async (data) => {
    try {
      const tmpData = data;

      if (!tmpData.soundMeter.timeHistoryActive) {
        tmpData.soundMeter.timeHistory = null;
      }
      else if (!tmpData.soundMeter.timeHistoryThirdOctaveActive) {
        tmpData.soundMeter.timeHistory.thirdOctave = null;
      }

      if (!tmpData.soundMeter.reportActive) {
        tmpData.soundMeter.report = null;
      }

      if (tmpData.soundMeter.report) {
        const intervals = ['TIME_1s',
          'TIME_2s',
          'TIME_5s',
          'TIME_10s',
          'TIME_20s',
          'TIME_30s',
        ];
        tmpData.soundMeter.report.thirdOctave = !!tmpData.soundMeter.report.thirdOctave;
        tmpData.soundMeter.report.statistics = !!tmpData.soundMeter.report.statistics;

        if (tmpData.soundMeter.report.statistics
          && intervals.includes(tmpData.soundMeter.report.interval)) {
          tmpData.soundMeter.report.statistics = false;
        }
      }

      delete tmpData.soundMeter.timeHistoryActive;
      delete tmpData.soundMeter.timeHistoryThirdOctaveActive;
      delete tmpData.soundMeter.reportActive;

      await comboStationConfigUpdateMutationAction({
        variables: {
          input: {
            id: comboStation.id,
            configId: config.id,
            ...tmpData,
          },
        },
      });
      onClose();
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <Modal
      title={t('comboStations.components.configEditModal.title')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <ComponentLoader
        message={t('common.loading')}
        loading={loading}
      >
        <ComboStationConfigForm
          onFormSubmit={handleFormSubmit}
          canEdit={canEdit}
          comboStation={comboStation}
          config={config}
        />
      </ComponentLoader>
    </Modal>
  );
};

const propTypes = {
  comboStation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timezone: PropTypes.string,
  }).isRequired,
  config: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

ComboStationConfigEditModal.propTypes = propTypes;

export default ComboStationConfigEditModal;
