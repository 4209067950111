import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Result,
  Row,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { AVAILABLE_MODELS } from '../enums';

import SerialPort from '../../utils/serial';

const SoundMeterHD2SerieConnect = (props) => {
  const {
    onConnect,
    onError,
  } = props;

  const { t } = useTranslation();

  const handleConnect = async () => {
    const port = await SerialPort.connect({
      baudRate: 230400,
      // flowControl: 'hardware',
      bufferSize: 1024,
    });

    const serialPort = new SerialPort(port);

    if (!serialPort) {
      throw new Error();
    }

    const modelNumber = await serialPort.writeStringWithResponseString('SET:INSTR_MODEL:?\r\n', 3000, (data) => {
      if (data.length === 28 && data.startsWith('SET:INSTR_MODEL:')) {
        const splitted = data.split(':');
        return splitted[2].trim();
      }
      return null;
    });

    if (modelNumber == null || !Object.values(AVAILABLE_MODELS).includes(modelNumber)) {
      await serialPort.disconnect(port);
      onError();
    }
    else {
      onConnect(serialPort);
    }
  };

  return (
    <Row>
      <Col xs={24}>
        <Result
          title={t('soundMeterHD2Series.connect.infoTitle')}
          subTitle={t('soundMeterHD2Series.connect.infoSubTitle')}
          extra={[
            <Button type="primary" key="connect" onClick={handleConnect}>
              {t('soundMeterHD2Series.connect.connectButton')}
            </Button>,
          ]}
        />
      </Col>
    </Row>
  );
};

const propTypes = {
  onConnect: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

SoundMeterHD2SerieConnect.propTypes = propTypes;

export default SoundMeterHD2SerieConnect;
