import { gql } from '@apollo/client';
import { DEVICE_DETAIL_FRAGMENT } from '../devices/queries';

export const H2090_DETAIL_FRAGMENT = gql`
  fragment H2090DetailFragment on H2090 {
    ...DeviceDetailFragment
    workspaceId
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;

export const H2090_QUERY = gql`
query h2090Query($id: ID!) {
  viewer {
    device(id: $id) {
      ...H2090DetailFragment
    }
  }
}
${H2090_DETAIL_FRAGMENT}
`;

export const H2090_UPDATE_MUTATION = gql`
  mutation h2090UpdateMutation($input: H2090UpdateInput!) {
    h2090Update(input: $input) {
      h2090 {
        ...H2090DetailFragment
      }
    }
  }
  ${H2090_DETAIL_FRAGMENT}
`;

export const H2090_UPDATE_WORKSPACE_MUTATION = gql`
  mutation h2090UpdateMutation($input: H2090UpdateWorkspaceInput!) {
    h2090UpdateWorkspace(input: $input) {
      h2090 {
        ...H2090DetailFragment
      }
    }
  }
  ${H2090_DETAIL_FRAGMENT}
`;
