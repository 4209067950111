/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Divider,
} from 'antd';
import {
  PageSection,
  messageError,
  ComponentLoader,
  messageSuccess,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

import { ComboStationChangeUserForm, ComboStationChangeOwnersForm } from '../components';
import { DEVICE_CHANGE_USER_MUTATION, DEVICE_CHANGE_OWNERS_MUTATION } from '../../devices/queries';

const ComboStationUserTab = (props) => {
  const {
    comboStation,
  } = props;

  const { t } = useTranslation();

  const [
    deviceChangeUserAction,
    {
      loading: loadingChangeUser,
      error: errorChangeUser,
    },
  ] = useMutation(DEVICE_CHANGE_USER_MUTATION);

  const [
    deviceChangeOwnersAction,
    {
      loading: loadingChangeOwners,
      error: errorChangeOwners,
    },
  ] = useMutation(DEVICE_CHANGE_OWNERS_MUTATION);

  React.useEffect(() => {
    if (errorChangeUser || errorChangeOwners) {
      const errors = extractGraphQLErrors(errorChangeUser || errorChangeOwners, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [errorChangeUser, errorChangeOwners, t]);

  const handleChangeUserFormSubmit = async (data) => {
    try {
      await deviceChangeUserAction({
        variables: {
          input: {
            id: comboStation.id,
            email: data.email,
          },
        },
      });
      messageSuccess(t('common.successMessage'));
    }
    // eslint-disable-next-line no-empty
    catch (e) { }
  };

  const handleChangeOwnersFormSubmit = async (data) => {
    try {
      await deviceChangeOwnersAction({
        variables: {
          input: {
            id: comboStation.id,
            emails: data.emails,
          },
        },
      });
      messageSuccess(t('common.successMessage'));
    }
    // eslint-disable-next-line no-empty
    catch (e) { }
  };

  return (
    <PageSection
      title={t('comboStations.userTab.title')}
    >
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Divider>
            {t('comboStations.userTab.userTitle')}
          </Divider>
          <ComponentLoader
            message={t('common.loading')}
            loading={loadingChangeUser}
          >
            <ComboStationChangeUserForm
              comboStation={comboStation}
              onFormSubmit={handleChangeUserFormSubmit}
              canEdit={comboStation.iAmOwner}
            />
          </ComponentLoader>
        </Col>

        <Col xs={24} md={12}>
          <Divider>
            {t('comboStations.userTab.ownersTitle')}
          </Divider>
          <ComponentLoader
            message={t('common.loading')}
            loading={loadingChangeOwners}
          >
            <ComboStationChangeOwnersForm
              comboStation={comboStation}
              onFormSubmit={handleChangeOwnersFormSubmit}
              canEdit={comboStation.iAmOwner}
            />
          </ComponentLoader>
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  comboStation: PropTypes.object.isRequired,
};

ComboStationUserTab.propTypes = propTypes;

export default ComboStationUserTab;
