import { messageError, PageSection } from '@deltaohm/ant-components';
import {
  Alert,
  Col,
  Row,
  Typography,
  Tabs,
} from 'antd';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ErrorList from 'antd/lib/form/ErrorList';
import React from 'react';

import SerialPort from '../utils/serial';

import { SoundMeterHD2SerieConnect } from './components';

import { AVAILABLE_MODELS } from './enums';

import {
  SoundMeterHD2SerieInfoTab,
  SoundMeterHD2SerieConfigsTab,
  SoundMeterHD2SerieDownloadTab,
} from './tabs';

const { TabPane } = Tabs;

const fetchInformation = async (serialPort) => {
  const serialNumber = await serialPort.writeStringWithResponseString('SET:INSTR_NUMBER:?\r\n', 3000, (data) => {
    if (data.startsWith('SET:INSTR_NUMBER:')) {
      const splitted = data.split(':');
      return splitted[2].trim();
    }
    return null;
  });

  const modelNumber = await serialPort.writeStringWithResponseString('SET:INSTR_MODEL:?\r\n', 3000, (data) => {
    if (data.startsWith('SET:INSTR_MODEL:')) {
      const splitted = data.split(':');
      return splitted[2].trim();
    }
    return null;
  });

  const option = await serialPort.writeStringWithResponseString('SET:OPTIONS:?\r\n', 3000, (data) => {
    if (data.startsWith('SET:OPTIONS:')) {
      const splitted = data.split(':');
      return splitted[2].trim();
    }
    return null;
  });

  const cla = await serialPort.writeStringWithResponseString('SET:CLASS:?\r\n', 3000, (data) => {
    if (data.startsWith('SET:CLASS:')) {
      const splitted = data.split(':');
      return parseInt(splitted[2].trim(), 10);
    }
    return null;
  });

  const now = new Date();
  const year = now.getFullYear().toString().padStart(4, '0');
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');

  const dateCommand = `SET:DATE:${year}:${month}:${day}\r\n`;

  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');

  const timeCommand = `SET:TIME:${hours}:${minutes}:${seconds}\r\n`;

  await serialPort.writeStringWithResponseString(dateCommand, 3000, () => true);
  await serialPort.writeStringWithResponseString(timeCommand, 3000, () => true);

  if (!serialNumber || !modelNumber || !option || cla == null) {
    return null;
  }

  return {
    serialNumber,
    model: modelNumber,
    option,
    cla,
  };
};

const SoundMeterHD2SerieManagePage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [connectedSoundMeter, setConnectedSoundMeter] = React.useState(null);

  const handleConnectError = () => {
    messageError({
      content: (
        <>
          <Typography.Title level={5}>
            {t('soundMeterHD2Series.manage.cannotConnect.title')}
          </Typography.Title>
          <ErrorList errors={[
            t('soundMeterHD2Series.manage.cannotConnect.link'),
            t('soundMeterHD2Series.manage.cannotConnect.port'),
            t('soundMeterHD2Series.manage.cannotConnect.model'),
            t('soundMeterHD2Series.manage.cannotConnect.usb'),
          ]}
          />
        </>
      ),
    });
  };

  const handleConnect = async (serialPort) => {
    const result = await fetchInformation(serialPort);
    if (result) {
      setConnectedSoundMeter({
        ...result,
        serialPort,
      });
    }
    else {
      await serialPort.disconnect();
      handleConnectError();
    }
  };

  const activeTab = React.useMemo(() => {
    const tab = searchParams.get('tab');
    if (!tab) {
      return 'info';
    }
    return tab;
  }, [searchParams]);

  const handleChangeTab = React.useCallback((newTab) => {
    setSearchParams({ tab: newTab });
  }, [setSearchParams]);

  const renderConnect = () => {
    if (!SerialPort.isAvailable()) {
      return (
        <Row>
          <Col xs={24}>
            <Alert
              message={t('soundMeterHD2Series.manage.serial')}
              description={t('soundMeterHD2Series.manage.serialUnavailable')}
              type="error"
              showIcon
            />
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col xs={24}>
          <SoundMeterHD2SerieConnect
            onConnect={handleConnect}
            onError={handleConnectError}
          />
        </Col>
      </Row>
    );
  };

  const renderContent = () => {
    const result = (
      <Tabs activeKey={activeTab} onChange={handleChangeTab}>
        <TabPane tab={t('soundMeterHD2Series.manage.tabs.info')} key="info">
          <SoundMeterHD2SerieInfoTab
            soundMeter={connectedSoundMeter}
          />
        </TabPane>
        <TabPane tab={t('soundMeterHD2Series.manage.tabs.configs')} key="configs">
          <SoundMeterHD2SerieConfigsTab
            soundMeter={connectedSoundMeter}
          />
        </TabPane>
        <TabPane tab={t('soundMeterHD2Series.manage.tabs.download')} key="download">
          <SoundMeterHD2SerieDownloadTab
            soundMeter={connectedSoundMeter}
          />
        </TabPane>
      </Tabs>
    );
    return result;
  };

  return (
    <PageSection
      title={t('soundMeterHD2Series.manage.title')}
      subtitle={t('soundMeterHD2Series.manage.subtitle')}
    >
      {!connectedSoundMeter && renderConnect()}
      {connectedSoundMeter && renderContent()}
    </PageSection>
  );
};

export default SoundMeterHD2SerieManagePage;
