import React from 'react';

import {
  Tabs,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { ComponentLoader, messageError, PageSection } from '@deltaohm/ant-components';
import { useComboStation } from './hooks';
import { extractGraphQLErrors } from '../utils/graphql';
import { ErrorList } from '../generics';

import {
  ComboStationGeneralTab,
  ComboStationCurrentConfigTab,
  ComboStationConfigsTab,
  ComboStationUserTab,
} from './tabs';

const { TabPane } = Tabs;

const ComboStationDetailPage = () => {
  const { comboStationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const {
    error,
    loading,
    comboStation,
  } = useComboStation(comboStationId);

  const breadcrumb = React.useMemo(() => {
    const result = [
      {
        path: '/devices',
        breadcrumbName: t('breadcrumbs.devices'),
      },
    ];
    if (comboStation) {
      result.push({
        path: `/comboStations/${comboStationId}`,
        breadcrumbName: `${comboStation.name} - ${comboStation.serialNumber}`,
      });
    }
    return result;
  }, [comboStation]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const activeTab = React.useMemo(() => {
    const tab = searchParams.get('tab');
    if (!tab) {
      return 'general';
    }
    return tab;
  }, [searchParams]);

  const handleChangeTab = React.useCallback((newTab) => {
    setSearchParams({ tab: newTab });
  }, [setSearchParams]);

  return (
    <ComponentLoader
      message={t('common.loading')}
      loading={loading}
    >
      <PageSection
        title={comboStation ? `${comboStation.name} - ${comboStation.serialNumber}` : ''}
        breadcrumb={breadcrumb}
      >
        <Tabs activeKey={activeTab} onChange={handleChangeTab}>
          <TabPane tab={t('comboStations.detail.tabs.general')} key="general">
            {comboStation && (
              <ComboStationGeneralTab
                comboStation={comboStation}
              />
            )}
          </TabPane>
          <TabPane tab={t('comboStations.detail.tabs.currentConfig')} key="current-config">
            {comboStation && (
              <ComboStationCurrentConfigTab
                comboStation={comboStation}
              />
            )}
          </TabPane>
          <TabPane tab={t('comboStations.detail.tabs.configs')} key="configs">
            {comboStation && (
              <ComboStationConfigsTab
                comboStation={comboStation}
              />
            )}
          </TabPane>
          {comboStation && comboStation.iAmOwner && (
            <TabPane tab={t('comboStations.detail.tabs.user')} key="user">
              {comboStation && (
                <ComboStationUserTab
                  comboStation={comboStation}
                />
              )}
            </TabPane>
          )}
        </Tabs>
      </PageSection>
    </ComponentLoader>
  );
};

export default ComboStationDetailPage;
