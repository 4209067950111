/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { Select, Spin } from 'antd';
import { useDebouncedCallback } from 'use-debounce';

import { noiseStudioApolloClient } from '../apollo';

const WORKSPACES_QUERY = gql`
query workspacesQuery($filter: WorkspaceFilter) {
  viewer {
    workspaces(first: 50, filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
`;

const WORKSPACE_QUERY = gql`
query workspaceQuery($id: ID!) {
  viewer {
    workspace(id: $id) {
      id
      name
    }
  }
}
`;

const WorkspaceInput = (props) => {
  const {
    disabled,
    value,
    onChange,
  } = props;

  const {
    data: dataWorkspaces,
    loading: loadingWorkspaces,
    refetch: refetchWorkspaces,
  } = useQuery(WORKSPACES_QUERY, {
    variables: {
      filter: null,
    },
    client: noiseStudioApolloClient,
  });

  const {
    data: dataWorkspace,
    loading: loadingWorkspace,
    refetch: refetchWorkspace,
  } = useQuery(WORKSPACE_QUERY, {
    variables: {
      id: value,
    },
    skip: value == null,
    client: noiseStudioApolloClient,
  });

  React.useEffect(() => {
    if (value) {
      refetchWorkspace({
        id: value,
      });
    }
  }, [value]);

  const realValue = React.useMemo(() => {
    if (!value) {
      return null;
    }
    if (dataWorkspace && !loadingWorkspace) {
      const { viewer } = dataWorkspace;
      const { workspace } = viewer;
      return {
        label: workspace.name,
        value: workspace.id,
      };
    }
    return null;
  }, [dataWorkspace, loadingWorkspace]);

  const options = React.useMemo(() => {
    if (dataWorkspaces && !loadingWorkspaces) {
      const { viewer } = dataWorkspaces;
      const { workspaces: workspaceConnection } = viewer;
      return workspaceConnection.edges.map((edge) => ({
        label: edge.node.name,
        value: edge.node.id,
      }));
    }
    return [];
  }, [dataWorkspaces, loadingWorkspaces]);

  const handleSearch = useDebouncedCallback((search) => {
    refetchWorkspaces({
      filter: {
        search,
      },
    });
  }, 500);

  return (
    <Select
      allowClear
      showSearch
      disabled={disabled}
      value={realValue}
      onChange={onChange}
      optionLabelProp="label"
      options={options}
      filterOption={false}
      onSearch={handleSearch}
      notFoundContent={loadingWorkspaces ? <Spin size="small" /> : null}
    />
  );
};

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  value: null,
  disabled: false,
};

WorkspaceInput.propTypes = propTypes;
WorkspaceInput.defaultProps = defaultProps;

export default WorkspaceInput;
