/* eslint-disable no-await-in-loop */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
} from 'antd';
import { useMutation } from '@apollo/client';

import { messageError, messageSuccess, PageSection } from '@deltaohm/ant-components';
import { useTranslation } from 'react-i18next';
import { ErrorList, TimezoneInput, WorkspaceInput } from '../../generics';
import { WORKSPACE_UPLOAD_FILE_MUTATION } from '../queries';
import { extractGraphQLErrors } from '../../utils/graphql';
import { noiseStudioApolloClient } from '../../apollo';

const dump = async (t, soundMeter) => {
  const { serialPort } = soundMeter;

  const binAutoResponseRegExp = /Number of files: *(\d*).*Dump time:\d\d:\d\d:\d\d/;
  const binAutoResult = await serialPort.writeStringWithResponseString('DMP:BIN_AUTO\r\n', 3000, (data) => {
    if (data.includes('BINARY MEMORY DUMP') && data.match(binAutoResponseRegExp)) {
      return data;
    }
    return null;
  });

  if (!binAutoResult) {
    throw new Error(t('soundMeterHD2Series.downloadTab.errors.binAutoTimeoutOrInvalidMemoryFormat'));
  }

  const numberOfFiles = parseInt(binAutoResult.match(binAutoResponseRegExp)[1], 10);

  if (numberOfFiles === 0) {
    return null;
  }

  let endOfDump = false;
  const bins = [];
  while (!endOfDump) {
    // aspettare 256 + 2 bytes o END OF DUMP
    const response = await serialPort.writeStringWithResponse('DMP:NEXT\r\n', 3000, (data) => {
      if (data.length === 256 + 1 + 1) {
        return data;
      }
      if (data.length === 17) {
        const removeChars = data.slice(2, 13);
        const parsed = String.fromCharCode(...removeChars);
        if (parsed === 'END OF DUMP') {
          return 'END OF DUMP';
        }
      }
      return null;
    });
    if (!response) {
      throw new Error(t('soundMeterHD2Series.downloadTab.errors.binNextTimeout'));
    }
    if (response && response === 'END OF DUMP') {
      endOfDump = true;
    }
    if (response && response !== 'END OF DUMP') {
      const validData = response.slice(0, 256);
      bins.push(...validData);
    }
  }

  await serialPort.writeStringWithResponse('DMP:OFF\r\n', 3000, (data) => {
    const response = data.toString();
    if (response.trim() === 'DUMP OFF') {
      return true;
    }
    return null;
  });

  if (!bins.length) {
    return null;
  }

  const modelString = soundMeter.model.padEnd(7, ' ');
  const serialNumberString = soundMeter.serialNumber.padEnd(11, ' ');

  let modelBytes = modelString.split('').map((x) => x.charCodeAt(0));
  if (modelBytes.length > 7) {
    modelBytes = modelBytes.slice(0, 7);
  }

  let serialNumberBytes = serialNumberString.split('').map((x) => x.charCodeAt(0));
  if (serialNumberBytes.length > 11) {
    serialNumberBytes = serialNumberBytes.slice(0, 7);
  }

  const header = [
    0xBA,
    ...modelBytes, // 7 bytes
    ...(new Array(6).fill(0xFF)),
    ...serialNumberBytes, // 11 bytes
    ...(new Array(39).fill(0xFF)),
  ];

  const result = [
    ...header,
    ...bins,
  ];

  return result;
};

const SoundMeterHD2SerieDownloadTab = (props) => {
  const {
    soundMeter,
  } = props;

  const { t } = useTranslation();
  const [error, setError] = React.useState(null);
  const [dumping, setDumping] = React.useState(false);

  const [
    workspaceUploadFileMutationAction,
    {
      loading: uploadLoading,
      error: uploadError,
    },
  ] = useMutation(WORKSPACE_UPLOAD_FILE_MUTATION, {
    client: noiseStudioApolloClient,
  });

  React.useEffect(() => {
    if (error) {
      messageError({
        content: <ErrorList errors={[error.message]} />,
      });
    }
    if (uploadError) {
      const errors = extractGraphQLErrors(uploadError, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, uploadError, t]);

  const handleDownloadFormSubmit = async (data) => {
    setError(null);
    setDumping(true);
    try {
      const byteArray = await dump(t, soundMeter);
      if (!byteArray) {
        throw new Error(t('soundMeterHD2Series.downloadTab.errors.noFilesFound'));
      }
      let { fileName } = data;
      if (!fileName.endsWith('.dl5')) {
        fileName = `${fileName}.dl5`;
      }
      const binFile = new Blob([new Uint8Array(byteArray)], { type: 'application/octet-stream' });
      FileSaver.saveAs(binFile, fileName);
    }
    catch (e) {
      setError(e);
    }
    setDumping(false);
  };

  const handleUploadFormSubmit = async (data) => {
    setError(null);
    setDumping(true);
    try {
      const byteArray = await dump(t, soundMeter);
      if (!byteArray) {
        throw new Error(t('soundMeterHD2Series.downloadTab.errors.noFilesFound'));
      }

      let { fileName } = data;
      if (!fileName.endsWith('.dl5')) {
        fileName = `${fileName}.dl5`;
      }

      const {
        workspaceId,
        timezone,
        namePrefix,
      } = data;

      const file = new File([new Uint8Array(byteArray)], 'devices.dl5', { type: 'application/octet-stream' });
      await workspaceUploadFileMutationAction({
        variables: {
          input: {
            id: workspaceId,
            timezone,
            namePrefix,
            file,
          },
        },
      });
      messageSuccess(t('soundMeterHD2Series.downloadTab.uploadSuccess'));
    }
    catch (e) {
      setError(e);
    }
    setDumping(false);
  };

  return (
    <PageSection
      title={t('soundMeterHD2Series.downloadTab.title')}
    >
      <Row gutter={24}>
        <Col xs={12}>

          <Divider>
            {t('soundMeterHD2Series.downloadTab.downloadFile')}
          </Divider>
          <Row>
            <Col span={24}>
              <Form
                onFinish={handleDownloadFormSubmit}
                autoComplete="off"
              >
                <Form.Item
                  label={t('soundMeterHD2Series.downloadTab.fileName')}
                  name="fileName"
                  rules={[{ required: true, message: t('validations.required') }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <Button loading={dumping} type="primary" htmlType="submit" block>
                    {t('common.download')}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Divider>
            {t('soundMeterHD2Series.downloadTab.uploadNoiseStudio')}
          </Divider>
          <Row>
            <Col span={24}>
              <Form
                onFinish={handleUploadFormSubmit}
                autoComplete="off"
              >
                <Form.Item
                  label={t('soundMeterHD2Series.downloadTab.fileName')}
                  name="fileName"
                  rules={[{ required: true, message: t('validations.required') }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t('soundMeterHD2Series.downloadTab.workspaceId')}
                  name="workspaceId"
                  rules={[{ required: true, message: t('validations.required') }]}
                >
                  <WorkspaceInput />
                </Form.Item>

                <Form.Item
                  label={t('soundMeterHD2Series.downloadTab.timezone')}
                  name="timezone"
                  rules={[{ required: true, message: t('validations.required') }]}
                >
                  <TimezoneInput />
                </Form.Item>

                <Form.Item
                  label={t('soundMeterHD2Series.downloadTab.namePrefix')}
                  name="namePrefix"
                  rules={[{ required: true, message: t('validations.required') }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <Button loading={dumping || uploadLoading} type="primary" htmlType="submit" block>
                    {t('common.upload')}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  soundMeter: PropTypes.shape({
    model: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
    option: PropTypes.string.isRequired,
    serialPort: PropTypes.object.isRequired,
  }).isRequired,
};

SoundMeterHD2SerieDownloadTab.propTypes = propTypes;

export default SoundMeterHD2SerieDownloadTab;
