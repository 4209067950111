// const config = {
//   graphQLUri: 'http://localhost:4100/graphql',
//   noiseStudioGraphQLUri: 'http://localhost:4200/graphql',
//   oauthUrl: 'http://localhost:4000/oauth/authorize',
//   logoutUrl: 'http://localhost:4000/logout?redirectTo=http://localhost:3000',
//   client_id: 'devices_deltaohm_com',
//   redirect_uri: 'http://localhost:3000/login',
//   edit_profile_uri: 'http://localhost:4000/profile',
//   languages: ['it', 'en'],
//   defaultLanguage: 'it',
// };

const config = {
  graphQLUri: 'https://s-api-devices-deltaohm.geekcups.com/graphql',
  noiseStudioGraphQLUri: 'https://s-api-noise-studio-deltaohm.geekcups.com/graphql',
  oauthUrl: 'https://s-login-deltaohm.geekcups.com/oauth/authorize',
  logoutUrl: 'https://s-login-deltaohm.geekcups.com/logout?redirectTo=https://s-devices-deltaohm.geekcups.com',
  client_id: 'xhgji2hxd6uubtx21r7y',
  redirect_uri: 'https://s-devices-deltaohm.geekcups.com/login',
  edit_profile_uri: 'https://s-login-deltaohm.geekcups.com/profile',
  languages: ['it', 'en'],
  defaultLanguage: 'it',
};

export default config;
