/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Button,
  Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  SoundMeterHD2SerieConfigGeneralFields,
  SoundMeterHD2SerieConfigTimeHistoryFields,
  SoundMeterHD2SerieConfigReportFields,
} from './configs';

const SoundMeterConfigForm = (props) => {
  const {
    soundMeter,
    config,
    onFormSubmit,
  } = props;

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const initialValues = React.useMemo(() => {
    if (config) {
      const result = JSON.parse(JSON.stringify(config));

      if (result.timeHistory) {
        result.timeHistoryActive = true;
        if (result.timeHistory.FFTBand) {
          result.timeHistory.FFTActive = true;
        }
      }
      else {
        result.timeHistoryActive = false;
      }
      if (result.report) {
        result.reportActive = true;
        if (result.report.statistics) {
          result.report.statisticsActive = true;
        }
      }
      else {
        result.reportActive = false;
      }
      return result;
    }
    return {};
  }, [config]);

  const renderSoundMeterConfigFields = () => (
    <>
      <Row>
        <Col span={24}>
          <SoundMeterHD2SerieConfigGeneralFields
            soundMeter={soundMeter}
            form={form}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SoundMeterHD2SerieConfigTimeHistoryFields
            soundMeter={soundMeter}
            form={form}
          />
        </Col>
      </Row>
      {(soundMeter.canReport) && (
        <Row>
          <Col span={24}>
            <SoundMeterHD2SerieConfigReportFields
              soundMeter={soundMeter}
              form={form}
            />
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={initialValues}
          form={form}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            {renderSoundMeterConfigFields()}

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {t('common.create')}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  soundMeter: PropTypes.object.isRequired,
  config: PropTypes.object,
  onFormSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  config: null,
};

SoundMeterConfigForm.propTypes = propTypes;
SoundMeterConfigForm.defaultProps = defaultProps;

export default SoundMeterConfigForm;
