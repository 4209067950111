/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Form,
  Input,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const H2090ChangeOwnersForm = (props) => {
  const {
    h2090,
    canEdit,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={{ emails: h2090.owners }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Form.List
            name="emails"
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field) => (
                  <Form.Item
                    key={field.key}
                  >
                    <Row align="middle" justify="space-between">
                      <Col xs={20}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[{ required: true, message: t('validations.required') }]}
                          noStyle
                        >
                          <Input disabled={!canEdit} style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col>
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t('common.add')}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {canEdit && (
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {t('common.save')}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  h2090: PropTypes.shape({
    id: PropTypes.string,
    owners: PropTypes.arrayOf(PropTypes.string),
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  h2090: null,
};

H2090ChangeOwnersForm.propTypes = propTypes;
H2090ChangeOwnersForm.defaultProps = defaultProps;

export default H2090ChangeOwnersForm;
