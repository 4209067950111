/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Divider,
} from 'antd';
import {
  PageSection,
  messageError,
  ComponentLoader,
  messageSuccess,
} from '@deltaohm/ant-components';
import { format } from 'date-fns';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';
import { COMBO_STATION_UPDATE_MUTATION } from '../queries';
import { ComboStationForm } from '../components';

const ComboStationConfigTab = (props) => {
  const {
    comboStation,
  } = props;

  const { t } = useTranslation();

  const [
    comboStationUpdateMutationAction,
    {
      loading: loadingUpdate,
      error: errorUpdate,
    },
  ] = useMutation(COMBO_STATION_UPDATE_MUTATION);

  React.useEffect(() => {
    if (errorUpdate) {
      const errors = extractGraphQLErrors(errorUpdate, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [errorUpdate, t]);

  const handleFormSubmit = async (data) => {
    try {
      await comboStationUpdateMutationAction({
        variables: {
          input: {
            id: comboStation.id,
            ...data,
          },
        },
      });
      messageSuccess(t('common.successMessage'));
    }
    // eslint-disable-next-line no-empty
    catch (e) { }
  };

  return (
    <PageSection
      title={t('comboStations.generalTab.title')}
    >
      <Row>
        <Col xs={24}>
          <ComponentLoader
            message={t('common.loading')}
            loading={loadingUpdate}
          >
            <ComboStationForm
              comboStation={comboStation}
              canEdit={comboStation.iAmOwner}
              onFormSubmit={handleFormSubmit}
            />
          </ComponentLoader>
        </Col>
      </Row>
      {comboStation.lastMonitorDate && (
        <>
          <Divider>
            {t('comboStations.generalTab.lastMonitor', { date: format(comboStation.lastMonitorDate, 'PPp') })}
          </Divider>
          <Row>
            <Col xs={24}>
              <pre>
                {comboStation.monitor}
              </pre>
            </Col>
          </Row>
        </>
      )}
      {comboStation.lastStatusDate && (
        <>
          <Divider>
            {t('comboStations.generalTab.lastStatus', { date: format(comboStation.lastStatusDate, 'PPp') })}
          </Divider>
          <Row>
            <Col xs={24}>
              <pre>
                {comboStation.status}
              </pre>
            </Col>
          </Row>
        </>
      )}
    </PageSection>
  );
};

const propTypes = {
  comboStation: PropTypes.object.isRequired,
};

ComboStationConfigTab.propTypes = propTypes;

export default ComboStationConfigTab;
