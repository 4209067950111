/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import {
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';

import SoundMeterHD2SerieConfigForm from './SoundMeterHD2SerieConfigForm';
import { SOUND_METER_HD2SERIE_UPDATE_CONFIG_MUTATION } from '../queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

const SoundMeterHD2SerieConfigEditModal = (props) => {
  const {
    soundMeter,
    config,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const [
    soundMeterHD2SerieConfigUpdateMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(SOUND_METER_HD2SERIE_UPDATE_CONFIG_MUTATION);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const handleFormSubmit = async (data) => {
    try {
      const tmpData = data;

      if (!tmpData.timeHistoryActive) {
        tmpData.soundMeter.timeHistory = null;
      }
      else if (!tmpData.timeHistory.FFTActive) {
        tmpData.timeHistory.FFTBand = null;
      }

      if (tmpData.timeHistory
        && tmpData.timeHistory.profile
        && !tmpData.timeHistory.profile.parameter) {
        tmpData.timeHistory.profile = null;
      }

      if (tmpData.timeHistory && !(tmpData.timeHistory.octave || tmpData.timeHistory.thirdOctave)) {
        delete tmpData.spectreConfig;
      }

      if (!tmpData.reportActive) {
        tmpData.report = null;
      }
      else {
        tmpData.report.octave = !!tmpData.report.octave;
        tmpData.report.thirdOctave = !!tmpData.report.thirdOctave;

        if (!tmpData.report.statisticsActive) {
          tmpData.report.statistics = null;
        }
      }

      delete tmpData.timeHistoryActive;
      delete tmpData.reportActive;

      if (tmpData.timeHistory) {
        delete tmpData.timeHistory.FFTActive;
      }

      if (tmpData.report) {
        delete tmpData.report.statisticsActive;
      }

      await soundMeterHD2SerieConfigUpdateMutationAction({
        variables: {
          input: {
            id: config.id,
            model: soundMeter.model,
            option: soundMeter.option,
            ...tmpData,
          },
        },
      });
      onClose();
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <Modal
      title={t('soundMeterHD2Series.components.configEditModal.title')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <ComponentLoader
        message={t('common.loading')}
        loading={loading}
      >
        <SoundMeterHD2SerieConfigForm
          onFormSubmit={handleFormSubmit}
          soundMeter={soundMeter}
          config={config}
        />
      </ComponentLoader>
    </Modal>
  );
};

const propTypes = {
  soundMeter: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SoundMeterHD2SerieConfigEditModal.propTypes = propTypes;

export default SoundMeterHD2SerieConfigEditModal;
