/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useQuery } from '@apollo/client';
import { H2090_QUERY } from './queries';

export const useH2090 = (h2090Id) => {
  const {
    error,
    loading,
    data,
  } = useQuery(H2090_QUERY, {
    variables: {
      id: h2090Id,
    },
  });

  const h2090 = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.device;
    }
    return null;
  }, [error, data, loading]);

  return {
    error,
    loading,
    h2090,
  };
};
