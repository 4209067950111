import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { TimezoneInput } from '../../generics';

const ComboStationForm = (props) => {
  const {
    comboStation,
    canEdit,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={comboStation}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Form.Item
            label={t('comboStations.components.form.name')}
            name="name"
            rules={[{ required: true, message: t('validations.required') }]}
          >
            <Input disabled={!canEdit} />
          </Form.Item>

          <Form.Item
            label={t('comboStations.components.form.timezone')}
            name="timezone"
            rules={[{ required: true, message: t('validations.required') }]}
          >
            <TimezoneInput disabled={!canEdit} />
          </Form.Item>

          {canEdit && (
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {t('common.save')}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  comboStation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    timezone: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  comboStation: null,
};

ComboStationForm.propTypes = propTypes;
ComboStationForm.defaultProps = defaultProps;

export default ComboStationForm;
