import { gql } from '@apollo/client';

const SOUND_METER_HD2SERIE_CONFIG_DETAIL_FRAGMENT = gql`
  fragment SoundMeterHD2SerieConfigDetailFragment on SoundMeterHD2SerieConfig {
    id
    name
    inputGain
    noiseOrigin
    windscreenCorrection
    overloadLevels
    doseExchangeRate
    doseThreshold
    doseCriterion
    l1
    l2
    l3
    l4
    timeHistory {
      profile {
        parameter
        pond
        time
      }
      slms {
        parameter
        pond
      }
      leqShortPond
      octave
      thirdOctave
      FFTBand
    }
    report {
      interval
      parameters {
        parameter
        pond
      }
      octave
      thirdOctave
      statistics {
        parameter
        pond
      }
    }
    spectreConfig {
      time
      type
      mean
      meanWeight
    }
  }
`;

export const SOUND_METER_HD2SERIE_DETAIL_FRAGMENT = gql`
  fragment SoundMeterHD2SerieDetailFragment on SoundMeterHD2Serie {
    availableInputGains
    availableNoiseOrigins
    availableWindscreenCorrections
    availableOverloads
    availableDoseExchangeRates
    availableDoseThresholds
    canLeqShort
    availableLeqShortPonds
    canProfile
    availableProfileTimes
    availableProfileParameters
    availableProfileParameterPonds {
      parameter
      pond
    }
    canSLM
    availableSLMParameters
    availableSLMParameterPonds {
      parameter
      pond
    }
    availableSLMParameterCount
    canTimeHistoryOctave
    canTimeHistoryThirdOctave
    availableSpectreTimes
    availableSpectreTypes
    availableSpectreMeans
    availableSpectreMeanWeights
    canFFT
    availableFFTBands
    availableStatisticsParameters
    availableStatisticsParametersPonds {
      parameter
      pond
    }
    canReport
    canReportOctave
    canReportThirdOctave
    canReportStatistics
    availableReportTimes
    availableReportParameters
    availableReportParameterPonds {
      parameter
      pond
    }
    availableReportParameterCount
    configs {
      ...SoundMeterHD2SerieConfigDetailFragment
    }
  }
  ${SOUND_METER_HD2SERIE_CONFIG_DETAIL_FRAGMENT}
`;

export const SOUND_METER_HD2SERIE_QUERY = gql`
query soundMeterHD2Serie($serialNumber: String!, $model: String!, $option: String!, $cla: Int!) {
  viewer {
    soundMeterHD2Serie(serialNumber: $serialNumber, model: $model, option: $option, cla: $cla) {
      ...SoundMeterHD2SerieDetailFragment
    }
  }
}
${SOUND_METER_HD2SERIE_DETAIL_FRAGMENT}
`;

export const SOUND_METER_HD2SERIE_CREATE_CONFIG_MUTATION = gql`
  mutation soundMeterHD2SerieCreateConfigMutation($input: SoundMeterHD2SerieCreateConfigInput!) {
    soundMeterHD2SerieCreateConfig(input: $input) {
      soundMeterHD2Serie {
        ...SoundMeterHD2SerieDetailFragment
      }
    }
  }
  ${SOUND_METER_HD2SERIE_DETAIL_FRAGMENT}
`;

export const SOUND_METER_HD2SERIE_UPDATE_CONFIG_MUTATION = gql`
  mutation soundMeterHD2SerieUpdateConfigMutation($input: SoundMeterHD2SerieUpdateConfigInput!) {
    soundMeterHD2SerieUpdateConfig(input: $input) {
      soundMeterHD2SerieConfig {
        ...SoundMeterHD2SerieConfigDetailFragment
      }
    }
  }
  ${SOUND_METER_HD2SERIE_CONFIG_DETAIL_FRAGMENT}
`;

export const SOUND_METER_HD2SERIE_DELETE_CONFIG_MUTATION = gql`
  mutation soundMeterHD2SerieDeleteConfigMutation($input: SoundMeterHD2SerieDeleteConfigInput!) {
    soundMeterHD2SerieDeleteConfig(input: $input) {
      soundMeterHD2Serie {
        ...SoundMeterHD2SerieDetailFragment
      }
    }
  }
  ${SOUND_METER_HD2SERIE_DETAIL_FRAGMENT}
`;

export const WORKSPACE_UPLOAD_FILE_MUTATION = gql`
  mutation WorkspaceUploadFileMutation($input: WorkspaceUploadFileInput!) {
    workspaceUploadFile(input: $input) {
      workspace {
        id
      }
    }
  }
`;
