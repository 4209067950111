/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import {
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';

import SoundMeterHD2SerieConfigForm from './SoundMeterHD2SerieConfigForm';
import { SOUND_METER_HD2SERIE_CREATE_CONFIG_MUTATION, SOUND_METER_HD2SERIE_QUERY } from '../queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

const SoundMeterHD2SerieConfigCreateModal = (props) => {
  const {
    soundMeter,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const [
    soundMeterHD2SerieConfigCreateMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(SOUND_METER_HD2SERIE_CREATE_CONFIG_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: SOUND_METER_HD2SERIE_QUERY,
          variables: {
            serialNumber: soundMeter.serialNumber,
            model: soundMeter.model,
            option: soundMeter.option,
            cla: soundMeter.cla,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const handleFormSubmit = async (data) => {
    try {
      const tmpData = data;

      if (!tmpData.timeHistoryActive) {
        tmpData.soundMeter.timeHistory = null;
      }
      else if (!tmpData.timeHistory.FFTActive) {
        tmpData.timeHistory.FFTBand = null;
      }

      if (tmpData.timeHistory
        && tmpData.timeHistory.profile
        && !tmpData.timeHistory.profile.parameter) {
        tmpData.timeHistory.profile = null;
      }

      if (tmpData.timeHistory && !(tmpData.timeHistory.octave || tmpData.timeHistory.thirdOctave)) {
        delete tmpData.spectreConfig;
      }

      if (!tmpData.reportActive) {
        tmpData.report = null;
      }
      else {
        tmpData.report.octave = !!tmpData.report.octave;
        tmpData.report.thirdOctave = !!tmpData.report.thirdOctave;

        if (!tmpData.report.statisticsActive) {
          tmpData.report.statistics = null;
        }
      }

      delete tmpData.timeHistoryActive;
      delete tmpData.reportActive;

      if (tmpData.timeHistory) {
        delete tmpData.timeHistory.FFTActive;
      }

      if (tmpData.report) {
        delete tmpData.report.statisticsActive;
      }

      await soundMeterHD2SerieConfigCreateMutationAction({
        variables: {
          input: {
            serialNumber: soundMeter.serialNumber,
            model: soundMeter.model,
            option: soundMeter.option,
            ...tmpData,
          },
        },
      });
      onClose();
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <Modal
      title={t('soundMeterHD2Series.components.configCreateModal.title')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <ComponentLoader
        message={t('common.loading')}
        loading={loading}
      >
        <SoundMeterHD2SerieConfigForm
          onFormSubmit={handleFormSubmit}
          soundMeter={soundMeter}
          config={null}
        />
      </ComponentLoader>
    </Modal>
  );
};

const propTypes = {
  soundMeter: PropTypes.any.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SoundMeterHD2SerieConfigCreateModal.propTypes = propTypes;

export default SoundMeterHD2SerieConfigCreateModal;
