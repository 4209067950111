/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import {
  PageSection,
  messageError,
  ComponentLoader,
  messageSuccess,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';
import { H2090_UPDATE_WORKSPACE_MUTATION } from '../queries';
import H2090UpdateWorkspaceForm from '../components/H2090UpdateWorkspaceForm';

const H2090UpdateWorkspaceTab = (props) => {
  const {
    h2090,
  } = props;

  const { t } = useTranslation();

  const [
    h2090UpdateWorkspaceAction,
    {
      loading: loadingUpdateWorkspace,
      error: errorUpdateWorkspace,
    },
  ] = useMutation(H2090_UPDATE_WORKSPACE_MUTATION);

  React.useEffect(() => {
    if (errorUpdateWorkspace) {
      const errors = extractGraphQLErrors(errorUpdateWorkspace, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [errorUpdateWorkspace, t]);

  const handleFormSubmit = async (data) => {
    try {
      await h2090UpdateWorkspaceAction({
        variables: {
          input: {
            id: h2090.id,
            workspaceId: data.workspaceId,
          },
        },
      });
      messageSuccess(t('common.successMessage'));
    }
    // eslint-disable-next-line no-empty
    catch (e) { }
  };

  return (
    <PageSection
      title={t('h2090s.updateWorkspaceTab.title')}
    >
      <Row>
        <Col xs={24}>
          <ComponentLoader
            message={t('common.loading')}
            loading={loadingUpdateWorkspace}
          >
            <H2090UpdateWorkspaceForm
              h2090={h2090}
              onFormSubmit={handleFormSubmit}
              canEdit={h2090.iAmUser}
            />
          </ComponentLoader>
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  h2090: PropTypes.object.isRequired,
};

H2090UpdateWorkspaceTab.propTypes = propTypes;

export default H2090UpdateWorkspaceTab;
