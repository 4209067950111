/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Tooltip,
  Button,
  Table,
  Space,
  Popconfirm,
} from 'antd';

import {
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
} from '@ant-design/icons';

import {
  PageSection,
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';
import { useSoundMeterHD2Serie } from '../hooks';
import {
  SoundMeterHD2SerieConfigCreateModal,
  SoundMeterHD2SerieConfigEditModal,
  SoundMeterHD2SerieSetConfigButton,
} from '../components';
import { SOUND_METER_HD2SERIE_DELETE_CONFIG_MUTATION } from '../queries';

const SoundMeterHD2SerieConfigsTab = (props) => {
  const {
    soundMeter,
  } = props;

  const { t } = useTranslation();

  const {
    error,
    loading,
    soundMeter: soundMeterQuery,
  } = useSoundMeterHD2Serie(soundMeter);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [editConfig, setEditConfig] = React.useState(null);

  const [
    comboStationConfigDeleteMutationAction,
    {
      loading: loadingDelete,
      error: errorDelete,
    },
  ] = useMutation(SOUND_METER_HD2SERIE_DELETE_CONFIG_MUTATION);

  const handleEdit = (config) => {
    setEditConfig(config);
  };

  React.useEffect(() => {
    if (errorDelete) {
      const errors = extractGraphQLErrors(errorDelete, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [errorDelete, t]);

  const columns = React.useMemo(() => {
    const result = [
      {
        title: t('soundMeterHD2Series.configsTab.table.name'),
        dataIndex: 'name',
      },
      {
        title: t('common.actions'),
        dataIndex: 'id',
        render: (id, record) => {
          const actions = (
            <Space size="small">
              <Tooltip title={t('common.edit')}>
                <Button shape="circle" type="primary" onClick={() => handleEdit(record)}>
                  <EditOutlined />
                </Button>
              </Tooltip>
              <Popconfirm
                title={t('common.sureToDelete')}
                okText={t('common.yes')}
                cancelText={t('common.no')}
                onConfirm={() => comboStationConfigDeleteMutationAction({
                  variables: {
                    input: {
                      id,
                    },
                  },
                })}
              >
                <Tooltip title={t('common.delete')}>
                  <Button shape="circle" type="primary">
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
              <Tooltip title={t('soundMeterHD2Series.configsTab.table.setConfig')}>
                <SoundMeterHD2SerieSetConfigButton config={record} soundMeter={soundMeterQuery} />
              </Tooltip>
            </Space>
          );
          return actions;
        },
      },
    ];
    return result;
  }, [t, soundMeterQuery]);

  return (
    <ComponentLoader
      message={t('common.loading')}
      loading={loading}
    >
      {soundMeterQuery && (
        <>
          <PageSection
            title={t('soundMeterHD2Series.configsTab.title')}
            headerExtras={[
              <Tooltip key="add-extra" title={t('common.create')}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={() => setCreateModalOpen(true)}
                />
              </Tooltip>,
            ]}
          >
            <Row>
              <Col xs={24}>
                <Table
                  bordered
                  size="small"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={soundMeterQuery.configs}
                  loading={loadingDelete}
                  pagination={false}
                />
              </Col>
            </Row>
          </PageSection>
          <SoundMeterHD2SerieConfigCreateModal
            soundMeter={soundMeterQuery}
            onClose={() => setCreateModalOpen(false)}
            isModalOpen={createModalOpen}
          />
          {editConfig && (
            <SoundMeterHD2SerieConfigEditModal
              soundMeter={soundMeterQuery}
              config={editConfig}
              onClose={() => setEditConfig(null)}
              isModalOpen={editConfig != null}
            />
          )}
        </>
      )}
    </ComponentLoader>
  );
};

const propTypes = {
  soundMeter: PropTypes.shape({
    model: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
    option: PropTypes.string.isRequired,
    cla: PropTypes.number.isRequired,
  }).isRequired,
};

SoundMeterHD2SerieConfigsTab.propTypes = propTypes;

export default SoundMeterHD2SerieConfigsTab;
