import { gql } from '@apollo/client';
import { DEVICE_DETAIL_FRAGMENT } from '../devices/queries';

const COMBO_STATION_CONFIG_DETAIL_FRAGMENT = gql`
  fragment ComboStationConfigDetailFragment on ComboStationConfig {
    id
    name
    sleepTime
    uploadCycle
    schedule {
      continuous
      startDate
      endDate
      continuous
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
    soundMeter {
      inputGain
      noiseOrigin
      l1
      l2
      l3
      l4
      timeHistory {
        profilePond
        slms {
          parameter
          pond
        }
        thirdOctave {
          mean
          meanWeight
        }
      }
      report {
        interval
        parameters {
          parameter
          pond
        }
        thirdOctave
        statistics
      }
    }
  }
`;

export const COMBO_STATION_DETAIL_FRAGMENT = gql`
  fragment ComboStationDetailFragment on ComboStation {
    ...DeviceDetailFragment
    timezone
    status
    monitor
    lastStatusDate
    lastMonitorDate
    soundMeter {
      serialNumber
      modelNumber
      option
      availableInputGains
      availableNoiseOrigins
      availableProfilePonds
      availableSLMParameters
      availableSLMParameterPonds {
        parameter
        pond
      }
      availableSLMParameterCount
      canTimeHistoryThirdOctave
      availableTimeHistoryThirdOctaveMeans
      availableTimeHistoryThirdOctaveMeanWeights
      availableReportTimes
      availableReportParameters
      availableReportParameterPonds {
        parameter
        pond
      }
      availableReportParameterCount
      canReportThirdOctave
    }
    configs {
      ...ComboStationConfigDetailFragment
    }
    currentConfig {
      config {
        ...ComboStationConfigDetailFragment
      }
      workspaceId
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
  ${COMBO_STATION_CONFIG_DETAIL_FRAGMENT}
`;

export const COMBO_STATION_QUERY = gql`
query comboStationQuery($id: ID!) {
  viewer {
    device(id: $id) {
      ...ComboStationDetailFragment
    }
  }
}
${COMBO_STATION_DETAIL_FRAGMENT}
`;

export const COMBO_STATION_UPDATE_MUTATION = gql`
  mutation comboStationUpdateMutation($input: ComboStationUpdateInput!) {
    comboStationUpdate(input: $input) {
      comboStation {
        ...ComboStationDetailFragment
      }
    }
  }
  ${COMBO_STATION_DETAIL_FRAGMENT}
`;

export const COMBO_STATION_CREATE_CONFIG_MUTATION = gql`
  mutation comboStationCreateConfigMutation($input: ComboStationCreateConfigInput!) {
    comboStationCreateConfig(input: $input) {
      comboStation {
        ...ComboStationDetailFragment
      }
    }
  }
  ${COMBO_STATION_DETAIL_FRAGMENT}
`;

export const COMBO_STATION_UPDATE_CONFIG_MUTATION = gql`
  mutation comboStationUpdateConfigMutation($input: ComboStationUpdateConfigInput!) {
    comboStationUpdateConfig(input: $input) {
      comboStation {
        ...ComboStationDetailFragment
      }
    }
  }
  ${COMBO_STATION_DETAIL_FRAGMENT}
`;

export const COMBO_STATION_DELETE_CONFIG_MUTATION = gql`
  mutation comboStationDeleteConfigMutation($input: ComboStationDeleteConfigInput!) {
    comboStationDeleteConfig(input: $input) {
      comboStation {
        ...ComboStationDetailFragment
      }
    }
  }
  ${COMBO_STATION_DETAIL_FRAGMENT}
`;

export const COMBO_STATION_CHANGE_CURRENT_CONFIG_MUTATION = gql`
  mutation comboStationChangeCurrentConfigMutation($input: ComboStationChangeCurrentConfigInput!) {
    comboStationChangeCurrentConfig(input: $input) {
      comboStation {
        ...ComboStationDetailFragment
      }
    }
  }
  ${COMBO_STATION_DETAIL_FRAGMENT}
`;
