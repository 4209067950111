import React from 'react';

import {
  Tabs,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { ComponentLoader, messageError, PageSection } from '@deltaohm/ant-components';
import { useH2090 } from './hooks';
import { extractGraphQLErrors } from '../utils/graphql';
import { ErrorList } from '../generics';

import {
  H2090GeneralTab,
  H2090UserTab,
} from './tabs';
import H2090UpdateWorkspaceTab from './tabs/H2090UpdateWorkspaceTab';

const { TabPane } = Tabs;

const H2090DetailPage = () => {
  const { h2090Id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const {
    error,
    loading,
    h2090,
  } = useH2090(h2090Id);

  const breadcrumb = React.useMemo(() => {
    const result = [
      {
        path: '/devices',
        breadcrumbName: t('breadcrumbs.devices'),
      },
    ];
    if (h2090) {
      result.push({
        path: `/h2090s/${h2090Id}`,
        breadcrumbName: `${h2090.name} - ${h2090.serialNumber}`,
      });
    }
    return result;
  }, [h2090]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const activeTab = React.useMemo(() => {
    const tab = searchParams.get('tab');
    if (!tab) {
      return 'general';
    }
    return tab;
  }, [searchParams]);

  const handleChangeTab = React.useCallback((newTab) => {
    setSearchParams({ tab: newTab });
  }, [setSearchParams]);

  return (
    <ComponentLoader
      message={t('common.loading')}
      loading={loading}
    >
      <PageSection
        title={h2090 ? `${h2090.name} - ${h2090.serialNumber}` : ''}
        breadcrumb={breadcrumb}
      >
        <Tabs activeKey={activeTab} onChange={handleChangeTab}>
          <TabPane tab={t('h2090s.detail.tabs.general')} key="general">
            {h2090 && (
              <H2090GeneralTab h2090={h2090} />
            )}
          </TabPane>
          <TabPane tab={t('h2090s.detail.tabs.updateWorkspace')} key="update-workspace">
            {h2090 && (
              <H2090UpdateWorkspaceTab
                h2090={h2090}
              />
            )}
          </TabPane>
          {h2090 && h2090.iAmOwner && (
            <TabPane tab={t('h2090s.detail.tabs.user')} key="user">
              {h2090 && (
                <H2090UserTab
                  h2090={h2090}
                />
              )}
            </TabPane>
          )}
        </Tabs>
      </PageSection>
    </ComponentLoader>
  );
};

export default H2090DetailPage;
