/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'antd';

import {
  timezonesNumeric,
  timezonesByCities,
} from '../enums/timezones';

import { getTimezoneLabelWithOffset } from '../utils/date';

const timezoneOptions = [
  ...timezonesNumeric,
  ...timezonesByCities,
];

const TimezoneInput = (props) => {
  const {
    disabled,
    value,
    onChange,
  } = props;

  const options = React.useMemo(() => {
    const result = timezoneOptions.map((item) => ({
      label: getTimezoneLabelWithOffset(item),
      value: item,
    }));
    return result;
  });

  return (
    <Select
      showSearch
      disabled={disabled}
      value={value}
      onChange={onChange}
      optionLabelProp="label"
      options={options}
      filterOption={(input, option) => {
        const result = option.value.toLowerCase().includes(input.toLowerCase());
        return result;
      }}
    />
  );
};

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  value: null,
  disabled: false,
};

TimezoneInput.propTypes = propTypes;
TimezoneInput.defaultProps = defaultProps;

export default TimezoneInput;
