import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';

const ComboStationChangeUserForm = (props) => {
  const {
    comboStation,
    canEdit,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={{ email: comboStation.user }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Form.Item
            label={t('comboStations.components.changeUserForm.email')}
            name="email"
          >
            <Input
              allowClear
              disabled={!canEdit}
            />
          </Form.Item>

          {canEdit && (
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {t('common.save')}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  comboStation: PropTypes.shape({
    id: PropTypes.string,
    user: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  comboStation: null,
};

ComboStationChangeUserForm.propTypes = propTypes;
ComboStationChangeUserForm.defaultProps = defaultProps;

export default ComboStationChangeUserForm;
