/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Tooltip,
  Button,
  Table,
  Space,
  Popconfirm,
} from 'antd';

import {
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
} from '@ant-design/icons';

import {
  PageSection,
  messageError,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';
import { COMBO_STATION_DELETE_CONFIG_MUTATION } from '../queries';
import {
  ComboStationConfigCreateModal,
  ComboStationConfigEditModal,
} from '../components';

const ComboStationConfigsTab = (props) => {
  const {
    comboStation,
  } = props;

  const { t } = useTranslation();

  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [editConfig, setEditConfig] = React.useState(null);

  const [
    comboStationConfigDeleteMutationAction,
    {
      loading: loadingDelete,
      error: errorDelete,
    },
  ] = useMutation(COMBO_STATION_DELETE_CONFIG_MUTATION);

  const handleEdit = (config) => {
    setEditConfig(config);
  };

  React.useEffect(() => {
    if (errorDelete) {
      const errors = extractGraphQLErrors(errorDelete, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [errorDelete, t]);

  const columns = React.useMemo(() => {
    const result = [
      {
        title: t('comboStations.configsTab.table.name'),
        dataIndex: 'name',
      },
    ];

    if (comboStation.iAmOwner) {
      result.push({
        title: t('common.actions'),
        dataIndex: 'id',
        render: (id, record) => {
          const actions = (
            <Space size="small">
              <Tooltip title={t('common.edit')}>
                <Button shape="circle" type="primary" onClick={() => handleEdit(record)}>
                  <EditOutlined />
                </Button>
              </Tooltip>
              <Popconfirm
                title={t('common.sureToDelete')}
                okText={t('common.yes')}
                cancelText={t('common.no')}
                onConfirm={() => comboStationConfigDeleteMutationAction({
                  variables: {
                    input: {
                      id: comboStation.id,
                      configId: id,
                    },
                  },
                })}
              >
                <Tooltip title={t('common.delete')}>
                  <Button shape="circle" type="primary">
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </Space>
          );
          return actions;
        },
      });
    }
    return result;
  }, [t, comboStation]);

  return (
    <>
      <PageSection
        title={t('comboStations.configsTab.title')}
        headerExtras={[
          <Tooltip key="add-extra" title={t('common.create')}>
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              onClick={() => setCreateModalOpen(true)}
            />
          </Tooltip>,
        ]}
      >
        <Row>
          <Col xs={24}>
            <Table
              bordered
              size="small"
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={comboStation.configs}
              loading={loadingDelete}
              pagination={false}
            />
          </Col>
        </Row>
      </PageSection>
      <ComboStationConfigCreateModal
        comboStation={comboStation}
        canEdit={comboStation.iAmOwner}
        onClose={() => setCreateModalOpen(false)}
        isModalOpen={createModalOpen}
      />
      {editConfig && (
        <ComboStationConfigEditModal
          comboStation={comboStation}
          config={editConfig}
          canEdit={comboStation.iAmOwner}
          onClose={() => setEditConfig(null)}
          isModalOpen={editConfig != null}
        />
      )}
    </>
  );
};

const propTypes = {
  comboStation: PropTypes.object.isRequired,
};

ComboStationConfigsTab.propTypes = propTypes;

export default ComboStationConfigsTab;
