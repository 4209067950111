/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import {
  PageSection,
  messageError,
  ComponentLoader,
  messageSuccess,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';
import { H2090_UPDATE_MUTATION } from '../queries';
import { H2090Form } from '../components';

const H2090GeneralTab = (props) => {
  const {
    h2090,
  } = props;

  const { t } = useTranslation();

  const [
    h2090UpdateMutationAction,
    {
      loading: loadingUpdate,
      error: errorUpdate,
    },
  ] = useMutation(H2090_UPDATE_MUTATION);

  React.useEffect(() => {
    if (errorUpdate) {
      const errors = extractGraphQLErrors(errorUpdate, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [errorUpdate, t]);

  const handleFormSubmit = async (data) => {
    try {
      await h2090UpdateMutationAction({
        variables: {
          input: {
            id: h2090.id,
            ...data,
          },
        },
      });
      messageSuccess(t('common.successMessage'));
    }
    // eslint-disable-next-line no-empty
    catch (e) { }
  };

  return (
    <PageSection
      title={t('comboStations.generalTab.title')}
    >
      <Row>
        <Col xs={24}>
          <ComponentLoader
            message={t('common.loading')}
            loading={loadingUpdate}
          >
            <H2090Form
              h2090={h2090}
              canEdit={h2090.iAmOwner}
              onFormSubmit={handleFormSubmit}
            />
          </ComponentLoader>
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  h2090: PropTypes.object.isRequired,
};

H2090GeneralTab.propTypes = propTypes;

export default H2090GeneralTab;
