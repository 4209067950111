import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Checkbox,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../../authentications';
import { USER_ROLES } from '../../enums';

// TODO: cercare di fare anche il null sulla checkbox
const DeviceFilter = (props) => {
  const { filter, onFilter } = props;
  const { t } = useTranslation();

  const { currentUser } = React.useContext(AuthenticationContext);

  const iAmAdmin = React.useMemo(() => currentUser.roles.includes(USER_ROLES.ADMIN), [currentUser]);

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={filter}
          onFinish={onFilter}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col xs={12} md={8}>
              <Form.Item
                label={t('devices.components.filter.search')}
                name="search"
              >
                <Input />
              </Form.Item>
            </Col>

            {iAmAdmin && (
              <Col xs={12} md={8}>
                <Form.Item
                  label={t('devices.components.filter.active')}
                  name="active"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {t('common.filter')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  filter: PropTypes.shape({
    search: PropTypes.string,
    active: PropTypes.bool,
  }),
  onFilter: PropTypes.func.isRequired,
};

const defaultProps = {
  filter: {},
};

DeviceFilter.propTypes = propTypes;
DeviceFilter.defaultProps = defaultProps;

export default DeviceFilter;
