/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Divider,
  Checkbox,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';

const SoundMeterConfigTimeHistoryFields = (props) => {
  const {
    form,
    soundMeter,
    canEdit,
  } = props;

  const { t } = useTranslation();

  const slmParameters = React.useMemo(() => {
    const result = [];
    for (let i = 0; i < soundMeter.availableSLMParameterCount; i += 1) {
      result.push(i);
    }
    return result;
  }, [soundMeter]);

  return (
    <Row>
      <Col span={24}>
        <Divider>
          {t('comboStations.components.configForm.soundMeter.timeHistoryDivider')}
        </Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t('comboStations.components.configForm.soundMeter.timeHistoryActive')}
              name={['soundMeter', 'timeHistoryActive']}
              valuePropName="checked"
            >
              <Checkbox
                disabled={!canEdit}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              noStyle
              dependencies={[['soundMeter', 'timeHistoryActive']]}
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label={t('comboStations.components.configForm.soundMeter.timeHistoryPond')}
                  name={['soundMeter', 'timeHistory', 'profilePond']}
                  rules={[{ required: getFieldValue(['soundMeter', 'timeHistoryActive']), message: t('validations.required') }]}
                >
                  <Select
                    disabled={!canEdit || !getFieldValue(['soundMeter', 'timeHistoryActive'])}
                    optionLabelProp="label"
                    options={
                      soundMeter.availableProfilePonds.map((pond) => ({
                        label: t(`enums.soundMeters.ponds.${pond}`),
                        value: pond,
                      }))
                    }
                    filterOption={(input, option) => {
                      const result = option.value.toLowerCase().includes(input.toLowerCase());
                      return result;
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          dependencies={[['soundMeter', 'timeHistoryActive']]}
        >
          {({ getFieldValue }) => (
            <>
              <Row gutter={24}>
                {slmParameters.map((slmParameter) => [
                  <Col span={12} key={`parameter_${slmParameter}`}>
                    <Form.Item
                      label={t('comboStations.components.configForm.soundMeter.timeHistorySlmParameter', { index: slmParameter + 1 })}
                      name={['soundMeter', 'timeHistory', 'slms', slmParameter, 'parameter']}
                      rules={[{ required: getFieldValue(['soundMeter', 'timeHistoryActive']), message: t('validations.required') }]}
                      initialValue={soundMeter.availableSLMParameters.length === 1
                        ? soundMeter.availableSLMParameters[0] : null}
                    >
                      <Select
                        disabled={!canEdit || !getFieldValue(['soundMeter', 'timeHistoryActive'])}
                        optionLabelProp="label"
                        options={
                          soundMeter.availableSLMParameters.map((parameter) => ({
                            label: t(`enums.soundMeters.parameters.${parameter}`),
                            value: parameter,
                          }))
                        }
                        filterOption={(input, option) => {
                          const result = option.value.toLowerCase().includes(input.toLowerCase());
                          return result;
                        }}
                      />
                    </Form.Item>
                  </Col>,
                  <Col span={12} key={`pond_${slmParameter}`}>
                    <Form.Item
                      noStyle
                      dependencies={[['soundMeter', 'timeHistory', 'slms', slmParameter, 'parameter']]}
                    >
                      {({ getFieldValue: getFieldValuePond }) => {
                        const parameter = getFieldValuePond(['soundMeter', 'timeHistory', 'slms', slmParameter, 'parameter']);
                        if (!parameter) {
                          return null;
                        }
                        const availablePonds = soundMeter.availableSLMParameterPonds
                          .filter((p) => p.parameter === parameter)
                          .map((p) => p.pond);
                        if (!availablePonds.length) {
                          return null;
                        }
                        return (
                          <Form.Item
                            label={t('comboStations.components.configForm.soundMeter.timeHistorySlmPond', { index: slmParameter + 1 })}
                            name={['soundMeter', 'timeHistory', 'slms', slmParameter, 'pond']}
                            rules={[
                              {
                                required: getFieldValue(['soundMeter', 'timeHistoryActive']),
                                message: t('validations.required'),
                              },
                              () => ({
                                validator(_, value) {
                                  if (!availablePonds.includes(value)) {
                                    const str = availablePonds.join(', ');
                                    return Promise.reject(new Error(t('validations.notInList', { items: str })));
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                            initialValue={availablePonds.length === 1
                              ? availablePonds[0] : null}
                          >
                            <Select
                              disabled={!canEdit || !getFieldValue(['soundMeter', 'timeHistoryActive'])}
                              optionLabelProp="label"
                              options={
                                availablePonds.map((pond) => ({
                                  label: t(`enums.soundMeters.ponds.${pond}`),
                                  value: pond,
                                }))
                              }
                              filterOption={(input, option) => {
                                const result = option.value.toLowerCase().includes(input.toLowerCase());
                                return result;
                              }}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>,
                ])}
              </Row>
              {soundMeter.canTimeHistoryThirdOctave && (
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item
                      label={t('comboStations.components.configForm.soundMeter.timeHistoryThirdOctaveActive')}
                      name={['soundMeter', 'timeHistoryThirdOctaveActive']}
                      valuePropName="checked"
                    >
                      <Checkbox
                        disabled={!canEdit || !getFieldValue(['soundMeter', 'timeHistoryActive'])}
                      />
                    </Form.Item>
                  </Col>
                  <Form.Item
                    noStyle
                    dependencies={[['soundMeter', 'timeHistoryActive'], ['soundMeter', 'timeHistoryThirdOctaveActive']]}
                  >
                    {({ getFieldValue: getFieldValueThirdOctave }) => [
                      <Col span={6} key="mean">
                        <Form.Item
                          label={t('comboStations.components.configForm.soundMeter.timeHistoryThirdOctaveMean')}
                          name={['soundMeter', 'timeHistory', 'thirdOctave', 'mean']}
                          rules={[{ required: getFieldValue(['soundMeter', 'timeHistoryActive']) && getFieldValueThirdOctave(['soundMeter', 'timeHistoryThirdOctaveActive']), message: t('validations.required') }]}
                          initialValue={soundMeter.availableTimeHistoryThirdOctaveMeans.length === 1
                            ? soundMeter.availableTimeHistoryThirdOctaveMeans[0] : null}
                        >
                          <Select
                            disabled={!canEdit || !getFieldValue(['soundMeter', 'timeHistoryActive']) || !getFieldValueThirdOctave(['soundMeter', 'timeHistoryThirdOctaveActive'])}
                            optionLabelProp="label"
                            options={
                              soundMeter.availableTimeHistoryThirdOctaveMeans.map((mean) => ({
                                label: t(`enums.soundMeters.thirdOctaveMeans.${mean}`),
                                value: mean,
                              }))
                            }
                            filterOption={(input, option) => {
                              const result = option.value.toLowerCase().includes(input.toLowerCase());
                              return result;
                            }}
                          />
                        </Form.Item>
                      </Col>,
                      <Col span={6} key="weight">
                        <Form.Item
                          label={t('comboStations.components.configForm.soundMeter.timeHistoryThirdOctaveMeanWeight')}
                          name={['soundMeter', 'timeHistory', 'thirdOctave', 'meanWeight']}
                          rules={[{ required: getFieldValue(['soundMeter', 'timeHistoryActive']) && getFieldValueThirdOctave(['soundMeter', 'timeHistoryThirdOctaveActive']), message: t('validations.required') }]}
                          initialValue={soundMeter.availableTimeHistoryThirdOctaveMeanWeights.length === 1
                            ? soundMeter.availableTimeHistoryThirdOctaveMeanWeights[0] : null}
                        >
                          <Select
                            disabled={!canEdit || !getFieldValue(['soundMeter', 'timeHistoryActive']) || !getFieldValueThirdOctave(['soundMeter', 'timeHistoryThirdOctaveActive'])}
                            optionLabelProp="label"
                            options={
                              soundMeter.availableTimeHistoryThirdOctaveMeanWeights.map((meanWeight) => ({
                                label: t(`enums.soundMeters.thirdOctaveMeanWeights.${meanWeight}`),
                                value: meanWeight,
                              }))
                            }
                            filterOption={(input, option) => {
                              const result = option.value.toLowerCase().includes(input.toLowerCase());
                              return result;
                            }}
                          />
                        </Form.Item>
                      </Col>,
                    ]}
                  </Form.Item>
                </Row>
              )}
            </>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  soundMeter: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

SoundMeterConfigTimeHistoryFields.propTypes = propTypes;

export default SoundMeterConfigTimeHistoryFields;
