/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Divider,
  Checkbox,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';

const SoundMeterConfigTimeHistoryFields = (props) => {
  const {
    soundMeter,
  } = props;

  const { t } = useTranslation();

  const slmParameters = React.useMemo(() => {
    const result = [];
    for (let i = 0; i < soundMeter.availableSLMParameterCount; i += 1) {
      result.push(i);
    }
    return result;
  }, [soundMeter]);

  return (
    <Row>
      <Col span={24}>
        <Divider>
          {t('soundMeterHD2Series.components.configForm.timeHistoryDivider')}
        </Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.timeHistoryActive')}
              name="timeHistoryActive"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        {soundMeter.canProfile && (
          <Form.Item
            noStyle
            dependencies={['timeHistoryActive']}
          >
            {({ getFieldValue }) => (
              <>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      label={t('soundMeterHD2Series.components.configForm.timeHistoryProfileParameter')}
                      name={['timeHistory', 'profile', 'parameter']}
                      initialValue={soundMeter.availableProfileParameters.length === 1
                        ? soundMeter.availableProfileParameters[0] : null}
                    >
                      <Select
                        allowClear
                        disabled={!getFieldValue('timeHistoryActive')}
                        optionLabelProp="label"
                        options={
                          soundMeter.availableProfileParameters.map((parameter) => ({
                            label: t(`enums.soundMeters.parameters.${parameter}`),
                            value: parameter,
                          }))
                        }
                        filterOption={(input, option) => {
                          const result = option.value.toLowerCase().includes(input.toLowerCase());
                          return result;
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      dependencies={[['timeHistory', 'profile', 'parameter']]}
                    >
                      {({ getFieldValue: getFieldValuePond }) => {
                        const parameter = getFieldValuePond(['timeHistory', 'profile', 'parameter']);
                        if (!parameter) {
                          return null;
                        }
                        const availablePonds = soundMeter.availableProfileParameterPonds
                          .filter((p) => p.parameter === parameter)
                          .map((p) => p.pond);
                        if (!availablePonds.length) {
                          return null;
                        }
                        return (
                          <Form.Item
                            label={t('soundMeterHD2Series.components.configForm.timeHistoryProfilePond')}
                            name={['timeHistory', 'profile', 'pond']}
                            rules={[
                              {
                                required: true,
                                message: t('validations.required'),
                              },
                              () => ({
                                validator(_, value) {
                                  if (!availablePonds.includes(value)) {
                                    const str = availablePonds.join(', ');
                                    return Promise.reject(new Error(t('validations.notInList', { items: str })));
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                            initialValue={availablePonds.length === 1
                              ? availablePonds[0] : null}
                          >
                            <Select
                              disabled={!getFieldValue('timeHistoryActive')}
                              optionLabelProp="label"
                              options={
                                availablePonds.map((pond) => ({
                                  label: t(`enums.soundMeters.ponds.${pond}`),
                                  value: pond,
                                }))
                              }
                              filterOption={(input, option) => {
                                const result = option.value.toLowerCase().includes(input.toLowerCase());
                                return result;
                              }}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      label={t('soundMeterHD2Series.components.configForm.timeHistoryProfileTime')}
                      name={['timeHistory', 'profile', 'time']}
                      rules={[
                        {
                          required: getFieldValue('timeHistoryActive'),
                          message: t('validations.required'),
                        },
                      ]}
                      initialValue={soundMeter.availableProfileTimes.length === 1
                        ? soundMeter.availableProfileTimes[0] : null}
                    >
                      <Select
                        disabled={!getFieldValue('timeHistoryActive')}
                        optionLabelProp="label"
                        options={
                          soundMeter.availableProfileTimes.map((profileTime) => ({
                            label: t(`enums.soundMeters.times.${profileTime}`),
                            value: profileTime,
                          }))
                        }
                        filterOption={(input, option) => {
                          const result = option.value.toLowerCase().includes(input.toLowerCase());
                          return result;
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.Item>
        )}
        {soundMeter.canSLM && (
          <Form.Item
            noStyle
            dependencies={['timeHistoryActive']}
          >
            {({ getFieldValue }) => (
              <Row gutter={24}>
                {slmParameters.map((slmParameter) => [
                  <Col span={12} key={`parameter_${slmParameter}`}>
                    <Form.Item
                      label={t('soundMeterHD2Series.components.configForm.timeHistorySlmParameter', { index: slmParameter + 1 })}
                      name={['timeHistory', 'slms', slmParameter, 'parameter']}
                      rules={[{ required: getFieldValue('timeHistoryActive'), message: t('validations.required') }]}
                      initialValue={soundMeter.availableSLMParameters.length === 1
                        ? soundMeter.availableSLMParameters[0] : null}
                    >
                      <Select
                        disabled={!getFieldValue('timeHistoryActive')}
                        optionLabelProp="label"
                        options={
                          soundMeter.availableSLMParameters.map((parameter) => ({
                            label: t(`enums.soundMeters.parameters.${parameter}`),
                            value: parameter,
                          }))
                        }
                        filterOption={(input, option) => {
                          const result = option.value.toLowerCase().includes(input.toLowerCase());
                          return result;
                        }}
                      />
                    </Form.Item>
                  </Col>,
                  <Col span={12} key={`pond_${slmParameter}`}>
                    <Form.Item
                      noStyle
                      dependencies={[['timeHistory', 'slms', slmParameter, 'parameter']]}
                    >
                      {({ getFieldValue: getFieldValuePond }) => {
                        const parameter = getFieldValuePond(['timeHistory', 'slms', slmParameter, 'parameter']);
                        if (!parameter) {
                          return null;
                        }
                        const availablePonds = soundMeter.availableSLMParameterPonds
                          .filter((p) => p.parameter === parameter)
                          .map((p) => p.pond);
                        if (!availablePonds.length) {
                          return null;
                        }
                        return (
                          <Form.Item
                            label={t('soundMeterHD2Series.components.configForm.timeHistorySlmPond', { index: slmParameter + 1 })}
                            name={['timeHistory', 'slms', slmParameter, 'pond']}
                            rules={[
                              {
                                required: getFieldValue('timeHistoryActive'),
                                message: t('validations.required'),
                              },
                              () => ({
                                validator(_, value) {
                                  if (!availablePonds.includes(value)) {
                                    const str = availablePonds.join(', ');
                                    return Promise.reject(new Error(t('validations.notInList', { items: str })));
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                            initialValue={availablePonds.length === 1
                              ? availablePonds[0] : null}
                          >
                            <Select
                              disabled={!getFieldValue('timeHistoryActive')}
                              optionLabelProp="label"
                              options={
                                availablePonds.map((pond) => ({
                                  label: t(`enums.soundMeters.ponds.${pond}`),
                                  value: pond,
                                }))
                              }
                              filterOption={(input, option) => {
                                const result = option.value.toLowerCase().includes(input.toLowerCase());
                                return result;
                              }}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>,
                ])}
              </Row>
            )}
          </Form.Item>
        )}
        {(soundMeter.canTimeHistoryOctave || soundMeter.canTimeHistoryThirdOctave) && (
          <>
            <Form.Item
              noStyle
              dependencies={['timeHistoryActive']}
            >
              {({ getFieldValue }) => (
                <Row gutter={24}>
                  {soundMeter.canTimeHistoryOctave && (
                    <Col span={12}>
                      <Form.Item
                        label={t('soundMeterHD2Series.components.configForm.timeHistoryOctave')}
                        name={['timeHistory', 'octave']}
                        valuePropName="checked"
                      >
                        <Checkbox
                          disabled={!getFieldValue('timeHistoryActive')}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {soundMeter.canTimeHistoryThirdOctave && (
                    <Col span={12}>
                      <Form.Item
                        label={t('soundMeterHD2Series.components.configForm.timeHistoryThirdOctave')}
                        name={['timeHistory', 'thirdOctave']}
                        valuePropName="checked"
                      >
                        <Checkbox
                          disabled={!getFieldValue('timeHistoryActive')}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              )}
            </Form.Item>
            <Form.Item
              noStyle
              dependencies={[['timeHistory', 'octave'], ['timeHistory', 'thirdOctave']]}
            >
              {({ getFieldValue }) => (
                <>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        label={t('soundMeterHD2Series.components.configForm.spectreTime')}
                        name={['spectreConfig', 'time']}
                        rules={[{ required: getFieldValue(['timeHistory', 'octave']) || getFieldValue(['timeHistory', 'thirdOctave']) || getFieldValue(['report', 'octave']) || getFieldValue(['report', 'thirdOctave']), message: t('validations.required') }]}
                      >
                        <Select
                          disabled={!getFieldValue(['timeHistory', 'octave']) && !getFieldValue(['timeHistory', 'thirdOctave']) && !getFieldValue(['report', 'octave']) && !getFieldValue(['report', 'thirdOctave'])}
                          optionLabelProp="label"
                          options={
                            soundMeter.availableSpectreTimes.map((spectreTime) => ({
                              label: t(`enums.soundMeters.times.${spectreTime}`),
                              value: spectreTime,
                            }))
                          }
                          filterOption={(input, option) => {
                            const result = option.value.toLowerCase().includes(input.toLowerCase());
                            return result;
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={t('soundMeterHD2Series.components.configForm.spectreType')}
                        name={['spectreConfig', 'type']}
                        rules={[{ required: getFieldValue(['timeHistory', 'octave']) || getFieldValue(['timeHistory', 'thirdOctave']) || getFieldValue(['report', 'octave']) || getFieldValue(['report', 'thirdOctave']), message: t('validations.required') }]}
                      >
                        <Select
                          disabled={!getFieldValue(['timeHistory', 'octave']) && !getFieldValue(['timeHistory', 'thirdOctave']) && !getFieldValue(['report', 'octave']) && !getFieldValue(['report', 'thirdOctave'])}
                          optionLabelProp="label"
                          options={
                            soundMeter.availableSpectreTypes.map((spectreType) => ({
                              label: t(`enums.soundMeters.spectreTypes.${spectreType}`),
                              value: spectreType,
                            }))
                          }
                          filterOption={(input, option) => {
                            const result = option.value.toLowerCase().includes(input.toLowerCase());
                            return result;
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        label={t('soundMeterHD2Series.components.configForm.spectreMean')}
                        name={['spectreConfig', 'mean']}
                        rules={[{ required: getFieldValue(['timeHistory', 'octave']) || getFieldValue(['timeHistory', 'thirdOctave']) || getFieldValue(['report', 'octave']) || getFieldValue(['report', 'thirdOctave']), message: t('validations.required') }]}
                      >
                        <Select
                          disabled={!getFieldValue(['timeHistory', 'octave']) && !getFieldValue(['timeHistory', 'thirdOctave']) && !getFieldValue(['report', 'octave']) && !getFieldValue(['report', 'thirdOctave'])}
                          optionLabelProp="label"
                          options={
                            soundMeter.availableSpectreMeans.map((spectreMean) => ({
                              label: t(`enums.soundMeters.spectreMeans.${spectreMean}`),
                              value: spectreMean,
                            }))
                          }
                          filterOption={(input, option) => {
                            const result = option.value.toLowerCase().includes(input.toLowerCase());
                            return result;
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={t('soundMeterHD2Series.components.configForm.spectreMeanWeight')}
                        name={['spectreConfig', 'meanWeight']}
                        rules={[{ required: getFieldValue(['timeHistory', 'octave']) || getFieldValue(['timeHistory', 'thirdOctave']) || getFieldValue(['report', 'octave']) || getFieldValue(['report', 'thirdOctave']), message: t('validations.required') }]}
                      >
                        <Select
                          disabled={!getFieldValue(['timeHistory', 'octave']) && !getFieldValue(['timeHistory', 'thirdOctave']) && !getFieldValue(['report', 'octave']) && !getFieldValue(['report', 'thirdOctave'])}
                          optionLabelProp="label"
                          options={
                            soundMeter.availableSpectreMeanWeights.map((meanWeight) => ({
                              label: t(`enums.soundMeters.spectreMeanWeights.${meanWeight}`),
                              value: meanWeight,
                            }))
                          }
                          filterOption={(input, option) => {
                            const result = option.value.toLowerCase().includes(input.toLowerCase());
                            return result;
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form.Item>
          </>
        )}
        {soundMeter.canLeqShort && (
          <Form.Item
            noStyle
            dependencies={['timeHistoryActive']}
          >
            {({ getFieldValue }) => (
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label={t('soundMeterHD2Series.components.configForm.leqShort')}
                    name={['timeHistory', 'leqShortPond']}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && !soundMeter.availableLeqShortPonds.includes(value)) {
                            const str = soundMeter.availableLeqShortPonds.join(', ');
                            return Promise.reject(new Error(t('validations.notInList', { items: str })));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    initialValue={soundMeter.availableLeqShortPonds.length === 1
                      ? soundMeter.availableLeqShortPonds[0] : null}
                  >
                    <Select
                      allowClear
                      disabled={!getFieldValue('timeHistoryActive')}
                      optionLabelProp="label"
                      options={
                        soundMeter.availableLeqShortPonds.map((pond) => ({
                          label: t(`enums.soundMeters.ponds.${pond}`),
                          value: pond,
                        }))
                      }
                      filterOption={(input, option) => {
                        const result = option.value.toLowerCase().includes(input.toLowerCase());
                        return result;
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form.Item>
        )}
        {soundMeter.canFFT && (
          <Form.Item
            noStyle
            dependencies={['timeHistoryActive']}
          >
            {({ getFieldValue }) => (
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label={t('soundMeterHD2Series.components.configForm.FFTActive')}
                    name={['timeHistory', 'FFTActive']}
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled={!getFieldValue('timeHistoryActive')}
                    />
                  </Form.Item>
                </Col>
                <Form.Item
                  noStyle
                  dependencies={['timeHistoryActive', ['timeHistory', 'FFTActive']]}
                >
                  {({ getFieldValue: getFieldValueFFT }) => (
                    <Col span={12}>
                      <Form.Item
                        label={t('soundMeterHD2Series.components.configForm.FFTBand')}
                        name={['timeHistory', 'FFTBand']}
                        rules={[{ required: getFieldValue('timeHistoryActive') && getFieldValueFFT(['timeHistory', 'FFTActive']), message: t('validations.required') }]}
                        initialValue={soundMeter.availableFFTBands.length === 1
                          ? soundMeter.availableFFTBands[0] : null}
                      >
                        <Select
                          disabled={!getFieldValue('timeHistoryActive') || !getFieldValueFFT(['timeHistory', 'FFTActive'])}
                          optionLabelProp="label"
                          options={
                            soundMeter.availableFFTBands.map((band) => ({
                              label: t(`enums.soundMeters.FFTBands.${band}`),
                              value: band,
                            }))
                          }
                          filterOption={(input, option) => {
                            const result = option.value.toLowerCase().includes(input.toLowerCase());
                            return result;
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Form.Item>
              </Row>
            )}
          </Form.Item>
        )}
      </Col>
    </Row>
  );
};

const propTypes = {
  soundMeter: PropTypes.object.isRequired,
};

SoundMeterConfigTimeHistoryFields.propTypes = propTypes;

export default SoundMeterConfigTimeHistoryFields;
