/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import {
  PageSection,
  messageError,
  ComponentLoader,
  messageSuccess,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';
import { COMBO_STATION_CHANGE_CURRENT_CONFIG_MUTATION } from '../queries';
import { ComboStationCurrentConfigForm } from '../components';

const ComboStationCurrentConfigTab = (props) => {
  const {
    comboStation,
  } = props;

  const { t } = useTranslation();

  const [
    comboStationChangeCurrentConfigMutationAction,
    {
      loading: loadingChangeCurrentConfig,
      error: errorChangeCurrentConfig,
    },
  ] = useMutation(COMBO_STATION_CHANGE_CURRENT_CONFIG_MUTATION);

  React.useEffect(() => {
    if (errorChangeCurrentConfig) {
      const errors = extractGraphQLErrors(errorChangeCurrentConfig, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [errorChangeCurrentConfig, t]);

  const handleFormSubmit = async (data) => {
    try {
      await comboStationChangeCurrentConfigMutationAction({
        variables: {
          input: {
            id: comboStation.id,
            configId: data.config.id,
            workspaceId: data.workspaceId,
          },
        },
      });
      messageSuccess(t('common.successMessage'));
    }
    // eslint-disable-next-line no-empty
    catch (e) { }
  };

  return (
    <PageSection
      title={t('comboStations.currentConfigTab.title')}
    >
      <Row>
        <Col xs={24}>
          <ComponentLoader
            message={t('common.loading')}
            loading={loadingChangeCurrentConfig}
          >
            <ComboStationCurrentConfigForm
              comboStation={comboStation}
              onFormSubmit={handleFormSubmit}
              canEdit={comboStation.iAmUser}
            />
          </ComponentLoader>
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  comboStation: PropTypes.object.isRequired,
};

ComboStationCurrentConfigTab.propTypes = propTypes;

export default ComboStationCurrentConfigTab;
