/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Modal,
  Progress,
  Row,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { messageError, messageSuccess } from '@deltaohm/ant-components';
import { ErrorList } from '../../generics';

import {
  AVAILABLE_MODELS,
  INPUT_GAINS,
  PARAMETERS,
  SPECTRE_AVERAGE_TYPES,
  SPECTRE_TYPES,
} from '../enums';

// eslint-disable-next-line no-promise-executor-return
const delay = (ms) => new Promise((resolve) => setTimeout(() => resolve(null), ms));

const SoundMeterHD2SerieSetConfigButton = (props) => {
  const {
    soundMeter,
    config,
  } = props;

  const { t } = useTranslation();

  const [sendConfigPercent, setSendConfigPercent] = React.useState(null);
  const [sendConfigError, setSendConfigError] = React.useState(null);

  React.useEffect(() => {
    if (sendConfigError) {
      messageError({
        content: <ErrorList errors={[
          t('soundMeterHD2Series.components.setConfigButton.sendConfigError'),
          ...sendConfigError,
        ]}
        />,
      });
    }
  }, [sendConfigError, t]);

  React.useEffect(() => {
    if (sendConfigPercent === 100) {
      messageSuccess(t('common.successMessage'));
      setSendConfigPercent(null);
    }
  }, [sendConfigPercent, t]);

  const handleSetConfig = async () => {
    setSendConfigError(null);
    const { serialPort } = soundMeter;
    const commands = [
      ['SET:AUTO_STORE:OFF\r\n', 'SET:AUTO_STORE:OFF\r\n'], // Fisso
      ['SET:INT_MODE:SING\r\n', 'SET:INT_MODE:SING\r\n'], // Fisso
    ];

    if (soundMeter.model === AVAILABLE_MODELS.HD2110L) {
      commands.push(['SET:IN_CHANNEL:MIC\r\n', 'SET:IN_CHANNEL:MIC\r\n']); // Fisso
      commands.push(['SET:AMB_MIC_CORR:ON\r\n', 'SET:AMB_MIC_CORR:ON\r\n']); // Fisso
    }

    commands.push([`SET:OVERLOAD_LEVEL:${config.overloadLevels.toString()}\r\n`, `SET:OVERLOAD_LEVEL:${config.overloadLevels.toString().padStart(3, ' ')}dB\r\n`]);
    commands.push([`SET:EXCHANGE_RATE:${config.doseExchangeRate.toString()}\r\n`, `SET:EXCHANGE_RATE:${config.doseExchangeRate.toString()}dB\r\n`]);
    commands.push([`SET:DOSE_THRESHOLD:${config.doseThreshold.toString()}\r\n`, `SET:DOSE_THRESHOLD:${config.doseThreshold.toString().padStart(3, ' ')}dB\r\n`]);
    commands.push([`SET:CRITERION_LEVEL:${config.doseCriterion.toString()}\r\n`, `SET:CRITERION_LEVEL:${config.doseCriterion.toString().padStart(3, ' ')}dB\r\n`]);

    commands.push([`SET:INPUT_GAIN:${INPUT_GAINS[config.inputGain].toString().padStart(2, '0')}\r\n`, `SET:INPUT_GAIN:${INPUT_GAINS[config.inputGain].toString().padStart(2, '0')}dB\r\n`]);
    commands.push([`SET:MIC_CORR:${config.noiseOrigin}\r\n`, `SET:MIC_CORR:${config.noiseOrigin}\r\n`]);
    commands.push([`SET:WND_SHL_CORR:${config.windscreenCorrection}\r\n`, `SET:WND_SHL_CORR:${config.windscreenCorrection}\r\n`]);

    if (soundMeter.model === AVAILABLE_MODELS.HD2110L) {
      commands.push([`SET:1_PERC_LEVEL:${config.l1.toString().padStart(2, '0')}\r\n`, `SET:1_PERC_LEVEL:${config.l1.toString()}%\r\n`]);
      commands.push([`SET:2_PERC_LEVEL:${config.l2.toString().padStart(2, '0')}\r\n`, `SET:2_PERC_LEVEL:${config.l2.toString()}%\r\n`]);
      commands.push([`SET:3_PERC_LEVEL:${config.l3.toString().padStart(2, '0')}\r\n`, `SET:3_PERC_LEVEL:${config.l3.toString()}%\r\n`]);
      commands.push([`SET:4_PERC_LEVEL:${config.l4.toString().padStart(2, '0')}\r\n`, `SET:4_PERC_LEVEL:${config.l4.toString()}%\r\n`]);
    }
    else {
      commands.push([`SET:1_PERC_LEVEL:${config.l1.toString().padStart(2, '0')}\r\n`, `SET:1_PERC_LEVEL:${config.l1.toString()}\r\n`]);
      commands.push([`SET:2_PERC_LEVEL:${config.l2.toString().padStart(2, '0')}\r\n`, `SET:2_PERC_LEVEL:${config.l2.toString()}\r\n`]);
      commands.push([`SET:3_PERC_LEVEL:${config.l3.toString().padStart(2, '0')}\r\n`, `SET:3_PERC_LEVEL:${config.l3.toString()}\r\n`]);
      commands.push([`SET:4_PERC_LEVEL:${config.l4.toString().padStart(2, '0')}\r\n`, `SET:4_PERC_LEVEL:${config.l4.toString()}\r\n`]);
    }

    if (config.timeHistory) {
      if (soundMeter.model === AVAILABLE_MODELS.HD2110L && config.timeHistory.profile) {
        const { parameter, pond, time } = config.timeHistory.profile;
        let command = `SET:PROFILE_PARAMETER:${PARAMETERS[parameter]}`;
        if (pond) {
          command = `${command}:${pond}`;
        }
        command = `${command}\r\n`;
        commands.push([command, command]);

        const realTime = time.replace('TIME_', '').replace('_', '.');
        commands.push([`SET:PROFILE_TIME:${realTime}\r\n`, `SET:PROFILE_TIME:${realTime}\r\n`]);
      }

      const slmCommands = config.timeHistory.slms.map((slm, i) => {
        let command = `SET:${i + 1}_SLM_PARAMETER:${PARAMETERS[slm.parameter]}`;
        let response;
        if (slm.pond != null) {
          command = `${command}:${slm.pond}\r\n`;
          response = command;
        }
        else {
          command = `${command}:`;
          response = `${command}U`;
          command = `${command}\r\n`;
        }
        const result = [command, response];
        return result;
      });
      commands.push(...slmCommands);

      if (soundMeter.model === AVAILABLE_MODELS.HD2110L) {
        commands.push(['SET:SLM_DLOGGER:ON\r\n', 'SET:SLM_DLOGGER:ON\r\n']);
        commands.push(['SET:PROF_DLOGGER:ON\r\n', 'SET:PROF_DLOGGER:ON\r\n']);
      }
      else {
        commands.push(['SET:SLM+PROF_DLOGGER:ON\r\n', 'SET:SLM+PROF_DLOGGER:ON\r\n']);
      }

      if (soundMeter.canLeqShort) {
        if (config.timeHistory.leqShortPond) {
          commands.push([`SET:LEQ_SHORT_DLOGGER:${config.timeHistory.leqShortPond}\r\n`, `SET:LEQ_SHORT_DLOGGER:${config.timeHistory.leqShortPond}\r\n`]);
        }
        else {
          commands.push(['SET:LEQ_SHORT_DLOGGER:OFF\r\n', 'SET:LEQ_SHORT_DLOGGER:OFF\r\n']);
        }
      }

      if (soundMeter.canTimeHistoryOctave) {
        if (config.timeHistory.octave) {
          commands.push(['SET:OCT_DLOGGER:ON\r\n', 'SET:OCT_DLOGGER:ON\r\n']);
        }
        else {
          commands.push(['SET:OCT_DLOGGER:OFF\r\n', 'SET:OCT_DLOGGER:OFF\r\n']);
        }
      }

      if (soundMeter.canTimeHistoryThirdOctave) {
        if (config.timeHistory.thirdOctave) {
          commands.push(['SET:TOCT_DLOGGER:ON\r\n', 'SET:TOCT_DLOGGER:ON\r\n']);
        }
        else {
          commands.push(['SET:TOCT_DLOGGER:OFF\r\n', 'SET:TOCT_DLOGGER:OFF\r\n']);
        }
      }

      if (soundMeter.canFFT) {
        if (config.timeHistory.FFTBand) {
          commands.push([`SET:FFT_BAND:${config.timeHistory.FFTBand}\r\n`, `SET:FFT_BAND:${config.timeHistory.FFTBand}\r\n`]);
          commands.push(['SET:FFT_DLOGGER:ON\r\n', 'SET:FFT_DLOGGER:ON\r\n']);
        }
        else {
          commands.push(['SET:FFT_DLOGGER:OFF\r\n', 'SET:FFT_DLOGGER:OFF\r\n']);
        }
      }
    }
    else {
      // eslint-disable-next-line no-lonely-if
      if (soundMeter.model === AVAILABLE_MODELS.HD2110L) {
        commands.push(['SET:SLM_DLOGGER:OFF\r\n', 'SET:SLM_DLOGGER:OFF\r\n']);
        commands.push(['SET:PROF_DLOGGER:OFF\r\n', 'SET:PROF_DLOGGER:OFF\r\n']);
        commands.push(['SET:OCT_DLOGGER:OFF\r\n', 'SET:OCT_DLOGGER:OFF\r\n']);
        commands.push(['SET:TOCT_DLOGGER:OFF\r\n', 'SET:TOCT_DLOGGER:OFF\r\n']);
        commands.push(['SET:LEQ_SHORT_DLOGGER:OFF\r\n', 'SET:LEQ_SHORT_DLOGGER:OFF\r\n']);
        commands.push(['SET:FFT_DLOGGER:OFF\r\n', 'SET:FFT_DLOGGER:OFF\r\n']);
      }
      else {
        commands.push(['SET:SLM+PROF_DLOGGER:OFF\r\n', 'SET:SLM+PROF_DLOGGER:OFF\r\n']);
        commands.push(['SET:OCT_DLOGGER:OFF\r\n', 'SET:OCT_DLOGGER:OFF\r\n']);
        commands.push(['SET:TOCT_DLOGGER:OFF\r\n', 'SET:TOCT_DLOGGER:OFF\r\n']);
      }
    }

    if (config.report) {
      const time = config.report.interval.replace('TIME_', '').replace('_', '.');

      commands.push([`SET:REPORT_TIME:${time}\r\n`, `SET:REPORT_TIME:${time}\r\n`]);
      const reportCommands = config.report.parameters.map((parameter, i) => {
        let command = `SET:${i + 1}_REP_PARAMETER:${PARAMETERS[parameter.parameter]}`;
        let response;
        if (parameter.pond != null) {
          command = `${command}:${parameter.pond}\r\n`;
          response = command;
        }
        else {
          command = `${command}:`;
          response = `${command}:U`;
          command = `${command}\r\n`;
        }
        const result = [command, response];
        return result;
      });
      commands.push(...reportCommands);

      commands.push(['SET:REP_PARAMETERS:ON\r\n', 'SET:REP_PARAMETERS:ON\r\n']);

      if (soundMeter.canReportOctave) {
        if (config.report.octave) {
          commands.push(['SET:REP_OCTAVE:ON\r\n', 'SET:REP_OCTAVE:ON\r\n']);
        }
        else {
          commands.push(['SET:REP_OCTAVE:OFF\r\n', 'SET:REP_OCTAVE:OFF\r\n']);
        }
      }

      if (soundMeter.canReportThirdOctave) {
        if (config.report.thirdOctave) {
          commands.push(['SET:REP_TOCTAVE:ON\r\n', 'SET:REP_TOCTAVE:ON\r\n']);
        }
        else {
          commands.push(['SET:REP_TOCTAVE:OFF\r\n', 'SET:REP_TOCTAVE:OFF\r\n']);
        }
      }
      if (config.report.statistics) {
        const { parameter, pond } = config.report.statistics;
        const command = `SET:STAT_PARAMETER:${PARAMETERS[parameter]}:${pond}\r\n`;
        commands.push([command, command]);
        commands.push(['SET:REP_STATISTICS:ON\r\n', 'SET:REP_STATISTICS:ON\r\n']);
      }
      else {
        commands.push(['SET:REP_STATISTICS:OFF\r\n', 'SET:REP_STATISTICS:OFF\r\n']);
      }
    }
    else {
      commands.push(['SET:REPORT_TIME:OFF\r\n', 'SET:REPORT_TIME:OFF\r\n']);
      commands.push(['SET:REP_PARAMETERS:OFF\r\n', 'SET:REP_PARAMETERS:OFF\r\n']);
      commands.push(['SET:REP_OCTAVE:OFF\r\n', 'SET:REP_OCTAVE:OFF\r\n']);
      commands.push(['SET:REP_TOCTAVE:OFF\r\n', 'SET:REP_TOCTAVE:OFF\r\n']);
      commands.push(['SET:REP_STATISTICS:OFF\r\n', 'SET:REP_STATISTICS:OFF\r\n']);
    }

    if ((soundMeter.canTimeHistoryOctave
      || soundMeter.canTimeHistoryThirdOctave)
      && config.spectreConfig
    ) {
      if (config.spectreConfig.time) {
        const time = config.spectreConfig.time.replace('TIME_', '').replace('_', '.');
        commands.push([`SET:SPECTRUM_TIME:${time}\r\n`, `SET:SPECTRUM_TIME:${time}\r\n`]);
      }
      if (config.spectreConfig.type) {
        const type = SPECTRE_TYPES[config.spectreConfig.type];
        commands.push([`SET:SPECT_TYPE:${type}\r\n`, `SET:SPECT_TYPE:${type}\r\n`]);
      }
      commands.push([`SET:SPECT_MEAN:${SPECTRE_AVERAGE_TYPES[config.spectreConfig.mean]}\r\n`, `SET:SPECT_MEAN:${SPECTRE_AVERAGE_TYPES[config.spectreConfig.mean]}\r\n`]);
      if (SPECTRE_AVERAGE_TYPES[config.spectreConfig.mean] === 'EXP') {
        commands.push([`SET:SPECT_MEAN_WEIGHT:${config.spectreConfig.meanWeight}\r\n`, `SET:SPECT_MEAN_WEIGHT:${config.spectreConfig.meanWeight}\r\n`]);
      }
    }

    const totalCommands = commands.length;

    setSendConfigPercent(0);

    let errors = [];
    try {
      for (let i = 0; i < commands.length; i += 1) {
        const command = commands[i][0];
        const validResponse = commands[i][1].trim().replace(/\s/g, '');
        await serialPort.writeStringWithResponseString(command, 1000, (data) => {
          const response = data.toString().trim().replace(/\s/g, '');
          if (response === validResponse) {
            errors = errors.filter((error) => !error.includes(command.trim()));
            return response;
          }
          if (!errors.some((error) => error.includes(command))) {
            errors.push(`${command.trim()} -> ${response.trim()}`);
          }
          return null;
        });
        await delay(25);
        setSendConfigPercent((i + 1) / totalCommands);
      }
    }
    catch (e) {
      errors.push(e.toString());
    }
    if (errors.length) {
      setSendConfigError(errors);
    }
    setSendConfigPercent(100);
  };

  if (sendConfigPercent === null) {
    return (
      <Button
        type="primary"
        shape="circle"
        icon={<DownloadOutlined />}
        onClick={() => handleSetConfig(true)}
      />
    );
  }

  return (
    <Modal
      title={t('soundMeterHD2Series.components.setConfigButton.modalTitle')}
      visible
      footer={null}
      closable={false}
    >
      <Row align="middle" justify="center">
        <Col>
          <Progress type="circle" percent={parseInt(sendConfigPercent * 100, 10)} size="small" status="active" />
        </Col>
      </Row>
    </Modal>
  );
};

const propTypes = {
  soundMeter: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};

SoundMeterHD2SerieSetConfigButton.propTypes = propTypes;

export default SoundMeterHD2SerieSetConfigButton;
