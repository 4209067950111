/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker,
} from '@deltaohm/ant-components';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const TimeRangeInput = (props) => {
  const {
    disabled,
    value,
    onChange,
    timezone,
  } = props;

  const handleChange = (event) => {
    if (event) {
      const [from, to] = event;
      const convertedFrom = zonedTimeToUtc(from, timezone);
      const convertedTo = zonedTimeToUtc(to, timezone);
      const fromDayDifference = from.getDate() - convertedFrom.getDate();
      const toDayDifference = to.getDate() - convertedTo.getDate();
      const fromArray = [
        convertedFrom.getUTCHours() - fromDayDifference * 24,
        convertedFrom.getUTCMinutes(),
        convertedFrom.getUTCSeconds(),
      ];
      const toArray = [
        convertedTo.getUTCHours() - toDayDifference * 24,
        convertedTo.getUTCMinutes(),
        convertedTo.getUTCSeconds(),
      ];
      onChange({
        from: fromArray,
        to: toArray,
      });
    }
    else {
      onChange(null);
    }
  };

  const realValue = React.useMemo(() => {
    if (value) {
      console.log(value);
      const [fromHour, fromMinute, fromSecond] = value.from;
      const [toHour, toMinute, toSecond] = value.to;
      const from = new Date();
      const to = new Date();
      from.setUTCHours(fromHour);
      from.setUTCMinutes(fromMinute);
      from.setUTCSeconds(fromSecond);
      to.setUTCHours(toHour);
      to.setUTCMinutes(toMinute);
      to.setUTCSeconds(toSecond);
      return [
        utcToZonedTime(from, timezone),
        utcToZonedTime(to, timezone),
      ];
    }
    return null;
  }, [value]);

  return (
    <DatePicker.RangePicker
      {...props}
      style={{ width: '100%' }}
      picker="time"
      disabled={disabled}
      value={realValue}
      onChange={handleChange}
    />
  );
};

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    from: PropTypes.arrayOf(Number).isRequired,
    to: PropTypes.arrayOf(Number).isRequired,
  }),
  disabled: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
};

const defaultProps = {
  value: null,
  disabled: false,
};

TimeRangeInput.propTypes = propTypes;
TimeRangeInput.defaultProps = defaultProps;

export default TimeRangeInput;
