import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';

const H2090ChangeUserForm = (props) => {
  const {
    h2090,
    canEdit,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={{ email: h2090.user }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Form.Item
            label={t('h2090s.components.changeUserForm.email')}
            name="email"
          >
            <Input
              allowClear
              disabled={!canEdit}
            />
          </Form.Item>

          {canEdit && (
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {t('common.save')}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  h2090: PropTypes.shape({
    id: PropTypes.string,
    user: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  h2090: null,
};

H2090ChangeUserForm.propTypes = propTypes;
H2090ChangeUserForm.defaultProps = defaultProps;

export default H2090ChangeUserForm;
