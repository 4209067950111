import React from 'react';
import PropTypes from 'prop-types';
import { Col, Descriptions, Row } from 'antd';

import { PageSection } from '@deltaohm/ant-components';
import { useTranslation } from 'react-i18next';

const SoundMeterHD2SerieInfoTab = (props) => {
  const {
    soundMeter,
  } = props;

  const { t } = useTranslation();

  return (
    <PageSection
      title={t('soundMeterHD2Series.infoTab.title')}
    >
      <Row>
        <Col xs={24}>
          <Descriptions bordered>
            <Descriptions.Item label={t('soundMeterHD2Series.infoTab.serialNumberLabel')}>
              {soundMeter.serialNumber}
            </Descriptions.Item>

            <Descriptions.Item label={t('soundMeterHD2Series.infoTab.modelNumberLabel')}>
              {soundMeter.model}
            </Descriptions.Item>

            <Descriptions.Item label={t('soundMeterHD2Series.infoTab.optionLabel')}>
              {soundMeter.option}
            </Descriptions.Item>

            <Descriptions.Item label={t('soundMeterHD2Series.infoTab.classLabel')}>
              {soundMeter.cla}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  soundMeter: PropTypes.shape({
    model: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
    option: PropTypes.string.isRequired,
    cla: PropTypes.number.isRequired,
  }).isRequired,
};

SoundMeterHD2SerieInfoTab.propTypes = propTypes;

export default SoundMeterHD2SerieInfoTab;
