/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Select,
  InputNumber,
} from 'antd';
import { useTranslation } from 'react-i18next';

const SoundMeterGeneralConfigFields = (props) => {
  const {
    soundMeter,
    canEdit,
  } = props;

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t('comboStations.components.configForm.soundMeter.inputGain')}
              name={['soundMeter', 'inputGain']}
              rules={[{ required: true, message: t('validations.required') }]}
              initialValue={soundMeter.availableInputGains.length === 1
                ? soundMeter.availableInputGains[0] : null}
            >
              <Select
                showSearch
                disabled={!canEdit}
                optionLabelProp="label"
                options={
                  soundMeter.availableInputGains.map((inputGain) => ({
                    label: t(`enums.soundMeters.inputGains.${inputGain}`),
                    value: inputGain,
                  }))
                }
                filterOption={(input, option) => {
                  const result = option.value.toLowerCase().includes(input.toLowerCase());
                  return result;
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('comboStations.components.configForm.soundMeter.noiseOrigin')}
              name={['soundMeter', 'noiseOrigin']}
              rules={[{ required: true, message: t('validations.required') }]}
              initialValue={soundMeter.availableNoiseOrigins.length === 1
                ? soundMeter.availableNoiseOrigins[0] : null}
            >
              <Select
                showSearch
                disabled={!canEdit}
                optionLabelProp="label"
                options={
                  soundMeter.availableNoiseOrigins.map((noiseOrigin) => ({
                    label: t(`enums.soundMeters.noiseOrigins.${noiseOrigin}`),
                    value: noiseOrigin,
                  }))
                }
                filterOption={(input, option) => {
                  const result = option.value.toLowerCase().includes(input.toLowerCase());
                  return result;
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label={t('comboStations.components.configForm.soundMeter.l1')}
              name={['soundMeter', 'l1']}
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                disabled={!canEdit}
                min={1}
                max={99}
                precision={0}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('comboStations.components.configForm.soundMeter.l2')}
              name={['soundMeter', 'l2']}
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                disabled={!canEdit}
                min={1}
                max={99}
                precision={0}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('comboStations.components.configForm.soundMeter.l3')}
              name={['soundMeter', 'l3']}
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                disabled={!canEdit}
                min={1}
                max={99}
                precision={0}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('comboStations.components.configForm.soundMeter.l4')}
              name={['soundMeter', 'l4']}
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                disabled={!canEdit}
                min={1}
                max={99}
                precision={0}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  soundMeter: PropTypes.object.isRequired,
};

SoundMeterGeneralConfigFields.propTypes = propTypes;

export default SoundMeterGeneralConfigFields;
