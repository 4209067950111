/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Button,
  Collapse,
  Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { GeneralConfigFields } from './generalConfigs';
import {
  SoundMeterConfigGeneralFields,
  SoundMeterConfigTimeHistoryFields,
  SoundMeterConfigReportFields,
} from './soundMeterConfigs';

const ComboStationConfigForm = (props) => {
  const {
    comboStation,
    config,
    canEdit,
    onFormSubmit,
  } = props;

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const initialValues = React.useMemo(() => {
    if (config) {
      const result = JSON.parse(JSON.stringify(config));

      if (result.soundMeter.timeHistory) {
        result.soundMeter.timeHistoryActive = true;
        if (result.soundMeter.timeHistory.thirdOctave) {
          result.soundMeter.timeHistoryThirdOctaveActive = true;
        }
        else {
          result.soundMeter.timeHistoryThirdOctaveActive = false;
        }
      }
      else {
        result.soundMeter.timeHistoryActive = false;
        result.soundMeter.timeHistoryThirdOctaveActive = false;
      }
      if (result.soundMeter.report) {
        result.soundMeter.reportActive = true;
      }
      else {
        result.soundMeter.reportActive = false;
      }
      return result;
    }

    const result = {};
    return result;
  }, [config]);

  const renderGeneralConfigFields = () => (
    <Row>
      <Col span={24}>
        <GeneralConfigFields
          canEdit={canEdit}
          comboStation={comboStation}
          form={form}
        />
      </Col>
    </Row>
  );

  const renderSoundMeterConfigFields = () => (
    <>
      <Row>
        <Col span={24}>
          <SoundMeterConfigGeneralFields
            canEdit={canEdit}
            soundMeter={comboStation.soundMeter}
            form={form}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SoundMeterConfigTimeHistoryFields
            canEdit={canEdit}
            soundMeter={comboStation.soundMeter}
            form={form}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SoundMeterConfigReportFields
            canEdit={canEdit}
            soundMeter={comboStation.soundMeter}
            form={form}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={initialValues}
          form={form}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Collapse defaultActiveKey="1">
              <Collapse.Panel header={t('comboStations.components.configForm.general.title')} key="1" forceRender>
                {renderGeneralConfigFields()}
              </Collapse.Panel>
            </Collapse>

            <Collapse>
              <Collapse.Panel header={t('comboStations.components.configForm.soundMeter.title')} key="2" forceRender>
                {renderSoundMeterConfigFields()}
              </Collapse.Panel>
            </Collapse>

            {canEdit && (
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {t('common.update')}
                </Button>
              </Form.Item>
            )}
          </Space>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  comboStation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    soundMeter: PropTypes.object.isRequired,
  }).isRequired,
  config: PropTypes.object,
  onFormSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  config: null,
};

ComboStationConfigForm.propTypes = propTypes;
ComboStationConfigForm.defaultProps = defaultProps;

export default ComboStationConfigForm;
