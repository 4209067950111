import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { WorkspaceInput } from '../../generics';

const H2090UpdateWorkspaceForm = (props) => {
  const {
    h2090,
    canEdit,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={h2090}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Form.Item
            noStyle
            dependencies={[['id']]}
          >
            {({ getFieldValue }) => {
              const v = getFieldValue(['id']);
              if (v == null) {
                return null;
              }
              return (
                <Form.Item
                  label={t('h2090s.components.updateWorkspaceForm.workspaceId')}
                  name="workspaceId"
                >
                  <WorkspaceInput
                    disabled={!canEdit}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          {canEdit && (
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {t('common.update')}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  h2090: PropTypes.shape({
    id: PropTypes.string.isRequired,
    workspaceId: PropTypes.string,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

H2090UpdateWorkspaceForm.propTypes = propTypes;

export default H2090UpdateWorkspaceForm;
