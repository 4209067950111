/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useQuery } from '@apollo/client';
import { SOUND_METER_HD2SERIE_QUERY } from './queries';

export const useSoundMeterHD2Serie = (inputSoundMeter) => {
  const {
    error,
    loading,
    data,
  } = useQuery(SOUND_METER_HD2SERIE_QUERY, {
    variables: {
      serialNumber: inputSoundMeter.serialNumber,
      model: inputSoundMeter.model,
      option: inputSoundMeter.option,
      cla: inputSoundMeter.cla,
    },
    fetchPolicy: 'network-only',
  });

  const soundMeter = React.useMemo(() => {
    if (!loading && !error) {
      return {
        serialNumber: inputSoundMeter.serialNumber,
        model: inputSoundMeter.model,
        option: inputSoundMeter.option,
        cla: inputSoundMeter.cla,
        serialPort: inputSoundMeter.serialPort,
        ...data.viewer.soundMeterHD2Serie,
      };
    }
    return null;
  }, [error, data, loading]);

  return {
    error,
    loading,
    soundMeter,
  };
};
