import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Select,
  Button,
  Checkbox,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { WorkspaceInput } from '../../generics';

const ComboStationCurrentConfigForm = (props) => {
  const {
    comboStation,
    canEdit,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  const options = React.useMemo(() => {
    const result = comboStation.configs.map((config) => ({
      label: config.name,
      value: config.id,
    }));
    return result;
  }, [comboStation]);

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={comboStation && comboStation.currentConfig}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Form.Item
            label={t('comboStations.components.currentConfigForm.configId')}
            name={['config', 'id']}
          >
            <Select
              disabled={!canEdit}
              allowClear
              showSearch
              optionLabelProp="label"
              options={options}
              filterOption={(input, option) => {
                const result = option.value.toLowerCase().includes(input.toLowerCase());
                return result;
              }}
            />
          </Form.Item>

          <Form.Item
            noStyle
            dependencies={[['config', 'id']]}
          >
            {({ getFieldValue }) => {
              const v = getFieldValue(['config', 'id']);
              if (v == null) {
                return null;
              }
              return (
                <Form.Item
                  label={t('comboStations.components.currentConfigForm.workspaceId')}
                  name="workspaceId"
                >
                  <WorkspaceInput
                    disabled={!canEdit}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          {canEdit && (
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {t('common.update')}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  comboStation: PropTypes.shape({
    configs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    currentConfig: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

ComboStationCurrentConfigForm.propTypes = propTypes;

export default ComboStationCurrentConfigForm;
