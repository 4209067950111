import React from 'react';

import {
  Routes,
  Route,
  Outlet,
  Link,
  useLocation,
} from 'react-router-dom';

import {
  Menu,
  Avatar,
  Dropdown,
} from 'antd';
import { SidebarLayout } from '@deltaohm/ant-components';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../authentications';

import config from '../config';

import {
  DeviceListPage,
} from '../devices';

import {
  ComboStationDetailPage,
} from '../comboStations';

import {
  H2090DetailPage,
} from '../h2090s';

import {
  SoundMeterHD2SerieManagePage,
} from '../soundMeterH2Series';

const AuthenticatedLayout = () => {
  const { currentUser, logout } = React.useContext(AuthenticationContext);
  const { t } = useTranslation();
  const location = useLocation();

  const initials = React.useMemo(() => {
    // eslint-disable-next-line prefer-regex-literals
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    const userInitials = [...currentUser.displayName.matchAll(rgx)] || [];

    const result = (
      (userInitials.shift()?.[1] || '') + (userInitials.pop()?.[1] || '')
    ).toUpperCase();

    return result;
  }, [currentUser]);

  const selectedSideMenuKeys = React.useMemo(() => {
    const result = [];
    if (location.pathname.includes('/devices')) {
      result.push('devices');
    }
    return result;
  }, [location]);

  const sideMenu = React.useMemo(() => (
    <Menu selectedKeys={selectedSideMenuKeys}>
      <Menu.Item key="devices">
        <Link to="/devices">
          {t('sideMenu.devices')}
        </Link>
      </Menu.Item>
      <Menu.Item key="soundMeterHD2Series">
        <Link to="/soundMeterHD2Series">
          {t('sideMenu.soundMeterHD2Series')}
        </Link>
      </Menu.Item>
    </Menu>
  ), [selectedSideMenuKeys]);

  const avatarMenu = React.useMemo(() => (
    <Menu>
      <Menu.Item key="editProfile">
        <a href={config.edit_profile_uri}>
          {t('rightMenu.editProfile')}
        </a>
      </Menu.Item>
      <Menu.Item key="logout" onClick={logout}>
        {t('rightMenu.logout')}
      </Menu.Item>
    </Menu>

  ), []);

  return (
    <SidebarLayout
      sideMenu={sideMenu}
      headerRight={(
        <Dropdown overlay={avatarMenu} placement="bottomCenter">
          <Avatar>{initials}</Avatar>
        </Dropdown>
      )}
    >
      <Routes>
        <Route path="devices" element={<Outlet />}>
          <Route path="" element={<DeviceListPage />} />
        </Route>
        <Route path="comboStations" element={<Outlet />}>
          <Route path=":comboStationId" element={<ComboStationDetailPage />} />
        </Route>
        <Route path="h2090s" element={<Outlet />}>
          <Route path=":h2090Id" element={<H2090DetailPage />} />
        </Route>
        <Route path="soundMeterHD2Series" element={<Outlet />}>
          <Route path="" element={<SoundMeterHD2SerieManagePage />} />
        </Route>
      </Routes>
    </SidebarLayout>
  );
};

export default AuthenticatedLayout;
