/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Select,
  InputNumber,
  Input,
} from 'antd';
import { useTranslation } from 'react-i18next';

const SoundMeterConfigGeneralFields = (props) => {
  const {
    soundMeter,
  } = props;

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.name')}
              name="name"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.inputGain')}
              name="inputGain"
              rules={[{ required: true, message: t('validations.required') }]}
              initialValue={soundMeter.availableInputGains.length === 1
                ? soundMeter.availableInputGains[0] : null}
            >
              <Select
                showSearch
                optionLabelProp="label"
                options={
                  soundMeter.availableInputGains.map((inputGain) => ({
                    label: t(`enums.soundMeters.inputGains.${inputGain}`),
                    value: inputGain,
                  }))
                }
                filterOption={(input, option) => {
                  const result = option.value.toLowerCase().includes(input.toLowerCase());
                  return result;
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.noiseOrigin')}
              name="noiseOrigin"
              rules={[{ required: true, message: t('validations.required') }]}
              initialValue={soundMeter.availableNoiseOrigins.length === 1
                ? soundMeter.availableNoiseOrigins[0] : null}
            >
              <Select
                showSearch
                optionLabelProp="label"
                options={
                  soundMeter.availableNoiseOrigins.map((noiseOrigin) => ({
                    label: t(`enums.soundMeters.noiseOrigins.${noiseOrigin}`),
                    value: noiseOrigin,
                  }))
                }
                filterOption={(input, option) => {
                  const result = option.value.toLowerCase().includes(input.toLowerCase());
                  return result;
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.windscreenCorrection')}
              name="windscreenCorrection"
              rules={[{ required: true, message: t('validations.required') }]}
              initialValue={soundMeter.availableWindscreenCorrections.length === 1
                ? soundMeter.availableWindscreenCorrections[0] : null}
            >
              <Select
                showSearch
                optionLabelProp="label"
                options={
                  soundMeter.availableWindscreenCorrections.map((windscreenCorrection) => ({
                    label: t(`enums.soundMeters.windscreenCorrections.${windscreenCorrection}`),
                    value: windscreenCorrection,
                  }))
                }
                filterOption={(input, option) => {
                  const result = option.value.toLowerCase().includes(input.toLowerCase());
                  return result;
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.l1')}
              name="l1"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                max={99}
                precision={0}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.l2')}
              name="l2"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                max={99}
                precision={0}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.l3')}
              name="l3"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                max={99}
                precision={0}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.l4')}
              name="l4"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                max={99}
                precision={0}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.overloadLevels')}
              name="overloadLevels"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={soundMeter.availableOverloads[0]}
                max={soundMeter.availableOverloads[1]}
                precision={0}
                step={soundMeter.availableOverloads[2]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.doseExchangeRate')}
              name="doseExchangeRate"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={soundMeter.availableDoseExchangeRates[0]}
                max={soundMeter.availableDoseExchangeRates[1]}
                precision={0}
                step={soundMeter.availableDoseExchangeRates[2]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.doseThreshold')}
              name="doseThreshold"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={soundMeter.availableDoseThresholds[0]}
                max={soundMeter.availableDoseThresholds[1]}
                precision={0}
                step={soundMeter.availableDoseThresholds[2]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('soundMeterHD2Series.components.configForm.doseCriterion')}
              name="doseCriterion"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                max={99}
                precision={0}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const propTypes = {
  soundMeter: PropTypes.object.isRequired,
};

SoundMeterConfigGeneralFields.propTypes = propTypes;

export default SoundMeterConfigGeneralFields;
