/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import {
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';

import ComboStationConfigForm from './ComboStationConfigForm';
import { COMBO_STATION_CREATE_CONFIG_MUTATION, COMBO_STATION_QUERY } from '../queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

const ComboStationConfigCreateModal = (props) => {
  const {
    canEdit,
    comboStation,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const [
    comboStationConfigCreateMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(COMBO_STATION_CREATE_CONFIG_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: COMBO_STATION_QUERY,
          variables: {
            id: comboStation.id,
            filter: { },
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const handleFormSubmit = async (data) => {
    try {
      const tmpData = data;

      if (!tmpData.soundMeter.timeHistoryActive) {
        tmpData.soundMeter.timeHistory = null;
      }
      else if (!tmpData.soundMeter.timeHistoryThirdOctaveActive) {
        tmpData.soundMeter.timeHistory.thirdOctave = null;
      }

      if (!tmpData.soundMeter.reportActive) {
        tmpData.soundMeter.report = null;
      }

      if (tmpData.soundMeter.report) {
        const intervals = ['TIME_1s',
          'TIME_2s',
          'TIME_5s',
          'TIME_10s',
          'TIME_20s',
          'TIME_30s',
        ];
        tmpData.soundMeter.report.thirdOctave = !!tmpData.soundMeter.report.thirdOctave;
        tmpData.soundMeter.report.statistics = !!tmpData.soundMeter.report.statistics;

        if (tmpData.soundMeter.report.statistics
          && intervals.includes(tmpData.soundMeter.report.interval)) {
          tmpData.soundMeter.report.statistics = false;
        }
      }

      delete tmpData.soundMeter.timeHistoryActive;
      delete tmpData.soundMeter.timeHistoryThirdOctaveActive;
      delete tmpData.soundMeter.reportActive;

      await comboStationConfigCreateMutationAction({
        variables: {
          input: {
            id: comboStation.id,
            ...tmpData,
          },
        },
      });
      onClose();
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <Modal
      title={t('comboStations.components.configCreateModal.title')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <ComponentLoader
        message={t('common.loading')}
        loading={loading}
      >
        <ComboStationConfigForm
          onFormSubmit={handleFormSubmit}
          canEdit={canEdit}
          comboStation={comboStation}
          config={null}
        />
      </ComponentLoader>
    </Modal>
  );
};

const propTypes = {
  comboStation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timezone: PropTypes.string,
  }).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

ComboStationConfigCreateModal.propTypes = propTypes;

export default ComboStationConfigCreateModal;
