/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  Tooltip,
  Space,
} from 'antd';
import {
  BugOutlined,
  CheckOutlined,
  CloseOutlined,
  PlayCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  PageSection,
  messageError,
} from '@deltaohm/ant-components';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';

import { format } from 'date-fns';

import { DEVICES_QUERY, DEVICE_CHANGE_ACTIVE_MUTATION, DEVICE_CHANGE_IN_MAINTENANCE_MUTATION } from './queries';
import { extractGraphQLErrors } from '../utils/graphql';
import { getLocale } from '../utils/date';

import { ErrorList } from '../generics';
import { DeviceFilter } from './components';
import { AuthenticationContext } from '../authentications';
import { USER_ROLES } from '../enums';

const DeviceListPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { currentUser } = React.useContext(AuthenticationContext);

  const locale = getLocale(i18n.language);

  const iAmAdmin = React.useMemo(() => {
    if (
      currentUser
      && currentUser.roles
      && currentUser.roles.includes(USER_ROLES.ADMIN)
    ) {
      return true;
    }
    return false;
  }, [currentUser]);

  const [filter, setFilter] = React.useState(null);

  const {
    data,
    error,
    loading,
    fetchMore,
  } = useQuery(DEVICES_QUERY, {
    variables: {
      pageSize: 100,
      filter: {
        ...filter,
      },
    },
    fetchPolicy: 'network-only',
  });

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { devices: deviceConnection } = viewer;
      const { pageInfo, edges } = deviceConnection;
      return {
        devices: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      devices: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  const [
    deviceChangeActiveMutation,
    {
      loading: loadingChangeActivate,
      error: errorChangeActive,
    },
  ] = useMutation(DEVICE_CHANGE_ACTIVE_MUTATION);

  const [
    deviceChangeInMaintenanceMutation,
    {
      loading: loadingChangeInMaintenance,
      error: errorChangeInMaintenance,
    },
  ] = useMutation(DEVICE_CHANGE_IN_MAINTENANCE_MUTATION);

  const handleToggleActive = React.useCallback(async (device) => {
    try {
      await deviceChangeActiveMutation({
        variables: {
          input: {
            id: device.id,
            active: !device.active,
          },
        },
      });
    }
    catch (e) { } // eslint-disable-line
  }, [deviceChangeActiveMutation]);

  const handleToggleInMaintenance = React.useCallback(async (device) => {
    try {
      await deviceChangeInMaintenanceMutation({
        variables: {
          input: {
            id: device.id,
            inMaintenance: !device.inMaintenance,
          },
        },
      });
    }
    catch (e) { } // eslint-disable-line
  }, [deviceChangeInMaintenanceMutation]);

  React.useEffect(() => {
    if (error || errorChangeActive || errorChangeInMaintenance) {
      const errors = extractGraphQLErrors(error
        || errorChangeActive || errorChangeInMaintenance, t);
      messageError({
        content: <ErrorList errors={errors || errorChangeActive || errorChangeInMaintenance} />,
      });
    }
  }, [error, errorChangeActive, errorChangeInMaintenance, t]);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        afterCursor: queryResult.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.devices;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult.viewer.devices;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              devices: {
                edges: [...oldEdges, ...newEdges],
                pageInfo: newPageInfo,
              },
            },
          }
          : previousResult;
      },
    });
  };

  const handleFilter = React.useCallback((newFilter) => {
    setFilter(newFilter);
  }, [setFilter]);

  const columns = React.useMemo(() => {
    const result = [
      {
        title: t('devices.list.table.serialNumber'),
        dataIndex: 'serialNumber',
      },
      {
        title: t('devices.list.table.typename'),
        dataIndex: '__typename',
      },
      {
        title: t('devices.list.table.name'),
        dataIndex: 'name',
      },
      {
        title: t('devices.list.table.lastOnline'),
        dataIndex: 'lastOnline',
        render: (lastOnline) => (
          lastOnline
            ? format(lastOnline, 'yyyy-MM-dd HH:mm:ss', { locale })
            : t('common.never')
        ),
      },
      {
        title: t('devices.list.table.nextOnline'),
        dataIndex: 'nextOnline',
        render: (nextOnline) => (
          nextOnline
            ? format(nextOnline, 'yyyy-MM-dd HH:mm:ss', { locale })
            : ''
        ),
      },
    ];

    if (iAmAdmin) {
      result.push({
        title: t('devices.list.table.active'),
        dataIndex: 'active',
        render: (active) => (active ? <CheckOutlined /> : <CloseOutlined />),
      });
      result.push({
        title: t('devices.list.table.inMaintenance'),
        dataIndex: 'inMaintenance',
        render: (inMaintenance) => (inMaintenance ? <CheckOutlined /> : <CloseOutlined />),
      });
    }

    result.push({
      title: t('common.actions'),
      dataIndex: 'id',
      render: (id, record) => {
        let path;
        if (record.__typename === 'ComboStation') {
          path = `/comboStations/${id}`;
        }
        if (record.__typename === 'H2090') {
          path = `/h2090s/${id}`;
        }
        const buttons = (
          <Space size="small">
            <Tooltip title={t('common.detail')}>
              <Button shape="circle" type="primary" onClick={() => navigate(path)}>
                <RightOutlined />
              </Button>
            </Tooltip>
            {iAmAdmin && (
              <>
                <Tooltip title={record.active ? t('devices.list.table.deactivate') : t('devices.list.table.activate')}>
                  <Button shape="circle" type="primary" onClick={() => handleToggleActive(record)}>
                    {record.active ? <CloseOutlined /> : <CheckOutlined /> }
                  </Button>
                </Tooltip>
                <Tooltip title={record.inMaintenance ? t('devices.list.table.maintenanceOff') : t('devices.list.table.maintenanceOn')}>
                  <Button shape="circle" type="primary" onClick={() => handleToggleInMaintenance(record)}>
                    {record.inMaintenance ? <PlayCircleOutlined /> : <BugOutlined /> }
                  </Button>
                </Tooltip>
              </>
            )}
          </Space>
        );
        return buttons;
      },
    });
    return result;
  }, [t, iAmAdmin]);

  const tableFooter = React.useMemo(() => {
    if (queryResult.pageInfo && queryResult.pageInfo.hasNextPage) {
      return () => (
        <Button type="primary" block onClick={handleLoadMore}>
          {t('common.loadMore')}
        </Button>
      );
    }
    return null;
  }, [queryResult, handleLoadMore]);

  return (
    <PageSection
      title={t('devices.list.title')}
      subtitle={t('devices.list.subtitle')}
    >
      <Row>
        <Col xs={24}>
          <DeviceFilter
            filter={filter}
            onFilter={handleFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Table
            bordered
            size="small"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={queryResult.devices}
            loading={loading || loadingChangeActivate}
            pagination={false}
            footer={tableFooter}
          />
        </Col>
      </Row>
    </PageSection>
  );
};

export default DeviceListPage;
