/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Divider,
  Checkbox,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';

const SoundMeterConfigReportFields = (props) => {
  const {
    soundMeter,
    canEdit,
  } = props;

  const { t } = useTranslation();

  const reportParameters = React.useMemo(() => {
    const result = [];
    for (let i = 0; i < soundMeter.availableReportParameterCount; i += 1) {
      result.push(i);
    }
    return result;
  }, [soundMeter]);

  return (
    <Row>
      <Col span={24}>
        <Divider>
          {t('comboStations.components.configForm.soundMeter.reportDivider')}
        </Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t('comboStations.components.configForm.soundMeter.reportActive')}
              name={['soundMeter', 'reportActive']}
              valuePropName="checked"
            >
              <Checkbox
                disabled={!canEdit}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              noStyle
              dependencies={[['soundMeter', 'reportActive']]}
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label={t('comboStations.components.configForm.soundMeter.reportInterval')}
                  name={['soundMeter', 'report', 'interval']}
                  rules={[{ required: getFieldValue(['soundMeter', 'reportActive']), message: t('validations.required') }]}
                  initialValue={soundMeter.availableReportTimes.length === 1
                    ? soundMeter.availableReportTimes[0] : null}
                >
                  <Select
                    disabled={!canEdit || !getFieldValue(['soundMeter', 'reportActive'])}
                    optionLabelProp="label"
                    options={
                      soundMeter.availableReportTimes.map((reportTime) => ({
                        label: t(`enums.soundMeters.times.${reportTime}`),
                        value: reportTime,
                      }))
                    }
                    filterOption={(input, option) => {
                      const result = option.value.toLowerCase().includes(input.toLowerCase());
                      return result;
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          dependencies={[['soundMeter', 'reportActive']]}
        >
          {({ getFieldValue }) => (
            <>
              <Row gutter={24}>
                {reportParameters.map((reportParameter) => (
                  <>
                    <Col span={12}>
                      <Form.Item
                        label={t('comboStations.components.configForm.soundMeter.reportParameter', { index: reportParameter + 1 })}
                        name={['soundMeter', 'report', 'parameters', reportParameter, 'parameter']}
                        rules={[{ required: getFieldValue(['soundMeter', 'reportActive']), message: t('validations.required') }]}
                        initialValue={soundMeter.availableReportParameters.length === 1
                          ? soundMeter.availableReportParameters[0] : null}
                      >
                        <Select
                          disabled={!canEdit || !getFieldValue(['soundMeter', 'reportActive'])}
                          optionLabelProp="label"
                          options={
                            soundMeter.availableReportParameters.map((parameter) => ({
                              label: t(`enums.soundMeters.parameters.${parameter}`),
                              value: parameter,
                            }))
                          }
                          filterOption={(input, option) => {
                            const result = option.value.toLowerCase().includes(input.toLowerCase());
                            return result;
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        noStyle
                        dependencies={[['soundMeter', 'report', 'parameters', reportParameter, 'parameter']]}
                      >
                        {({ getFieldValue: getFieldValuePond }) => {
                          const parameter = getFieldValuePond(['soundMeter', 'report', 'parameters', reportParameter, 'parameter']);
                          if (!parameter) {
                            return null;
                          }
                          const availablePonds = soundMeter.availableReportParameterPonds
                            .filter((p) => p.parameter === parameter)
                            .map((p) => p.pond);
                          if (!availablePonds.length) {
                            return null;
                          }
                          return (
                            <Form.Item
                              label={t('comboStations.components.configForm.soundMeter.reportPond', { index: reportParameter + 1 })}
                              name={['soundMeter', 'report', 'parameters', reportParameter, 'pond']}
                              rules={[
                                {
                                  required: getFieldValue(['soundMeter', 'reportActive']),
                                  message: t('validations.required'),
                                },
                                () => ({
                                  validator(_, value) {
                                    if (!availablePonds.includes(value)) {
                                      const str = availablePonds.join(', ');
                                      return Promise.reject(new Error(t('validations.notInList', { items: str })));
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              initialValue={availablePonds.length === 1
                                ? availablePonds[0] : null}
                            >
                              <Select
                                disabled={!canEdit || !getFieldValue(['soundMeter', 'reportActive'])}
                                optionLabelProp="label"
                                options={
                                  availablePonds.map((pond) => ({
                                    label: t(`enums.soundMeters.ponds.${pond}`),
                                    value: pond,
                                  }))
                                }
                                filterOption={(input, option) => {
                                  const result = option.value
                                    .toLowerCase().includes(input.toLowerCase());
                                  return result;
                                }}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </>
                ))}
              </Row>
              <Row gutter={24}>
                {soundMeter.canReportThirdOctave && (
                  <Col span={12}>
                    <Form.Item
                      label={t('comboStations.components.configForm.soundMeter.reportThirdOctave')}
                      name={['soundMeter', 'report', 'thirdOctave']}
                      valuePropName="checked"
                    >
                      <Checkbox
                        disabled={!canEdit || !getFieldValue(['soundMeter', 'reportActive'])}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={12}>
                  <Form.Item
                    noStyle
                    dependencies={[['soundMeter', 'report', 'interval']]}
                  >
                    {({ getFieldValue: getFieldValueInterval }) => (
                      <Form.Item
                        label={t('comboStations.components.configForm.soundMeter.reportStatistics')}
                        name={['soundMeter', 'report', 'statistics']}
                        valuePropName="checked"
                      >
                        <Checkbox
                          disabled={
                            !canEdit
                            || !getFieldValue(['soundMeter', 'reportActive'])
                            || !getFieldValueInterval(['soundMeter', 'report', 'interval'])
                            || [
                              'TIME_1s',
                              'TIME_2s',
                              'TIME_5s',
                              'TIME_10s',
                              'TIME_20s',
                              'TIME_30s',
                            ].includes(getFieldValueInterval(['soundMeter', 'report', 'interval']))
                          }
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  soundMeter: PropTypes.object.isRequired,
};

SoundMeterConfigReportFields.propTypes = propTypes;

export default SoundMeterConfigReportFields;
