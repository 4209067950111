/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Divider,
  Checkbox,
  Typography,
  Space,
  InputNumber,
} from 'antd';
import { DatePicker } from '@deltaohm/ant-components';
import { useTranslation } from 'react-i18next';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import TimeRangeInput from '../../../generics/TimeRangeInput';

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const GeneralConfigFields = (props) => {
  const {
    comboStation,
    canEdit,
  } = props;

  const { t } = useTranslation();

  const renderDay = (day) => {
    const result = (
      <Row key={day}>
        <Col span={24}>
          <Typography.Title level={5}>
            {t(`comboStations.components.configForm.general.${day}Title`)}
          </Typography.Title>
        </Col>
        <Form.List
          name={['schedule', day]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Form.Item
                  key={`schedule_${day}_${field.key}`}
                  dependencies={[['schedule', 'continuous']]}
                >
                  {({ getFieldValue }) => (
                    <Space align="baseline">
                      <Form.Item
                        {...field}
                        rules={[
                          {
                            type: 'object',
                            required: true,
                            message: t('validations.required'),
                          },
                        ]}
                      >
                        <TimeRangeInput
                          style={{ width: '100%' }}
                          timezone={comboStation.timezone}
                          disabled={getFieldValue(['schedule', 'continuous'])}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        disabled={getFieldValue(['schedule', 'continuous'])}
                        onClick={() => remove(field.name)}
                      />
                    </Space>
                  )}
                </Form.Item>
              ))}
              <Form.Item
                noStyle
                dependencies={[['schedule', 'continuous']]}
              >
                {({ getFieldValue }) => (
                  <Button
                    type="dashed"
                    disabled={getFieldValue(['schedule', 'continuous'])}
                    block
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    {t('comboStations.components.configForm.general.addSchedule')}
                  </Button>
                )}
              </Form.Item>
            </>
          )}
        </Form.List>
      </Row>
    );

    return result;
  };

  return (
    <Row>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={t('comboStations.components.configForm.general.name')}
              name="name"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <Input disabled={!canEdit} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={t('comboStations.components.configForm.general.sleepTime')}
              name="sleepTime"
              rules={[{ required: true, message: t('validations.required') }]}
            >

              <InputNumber
                style={{ width: '100%' }}
                disabled={!canEdit}
                min={5}
                precision={0}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={t('comboStations.components.configForm.general.uploadCycle')}
              name="uploadCycle"
              rules={[{ required: true, message: t('validations.required') }]}
            >

              <InputNumber
                style={{ width: '100%' }}
                disabled={!canEdit}
                min={1}
                max={5}
                precision={0}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider>
          {t('comboStations.components.configForm.general.scheduleDivider')}
        </Divider>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t('comboStations.components.configForm.general.startDate')}
              name={['schedule', 'startDate']}
              rules={[
                {
                  required: true,
                  message: t('validations.required'),
                },
              ]}
              getValueFromEvent={(event) => {
                let value = null;
                if (event != null) {
                  value = zonedTimeToUtc(event, comboStation.timezone);
                }
                return value;
              }}
              getValueProps={(value) => {
                if (value) {
                  return {
                    value: utcToZonedTime(value, comboStation.timezone),
                  };
                }
                return { value: null };
              }}
            >
              <DatePicker
                style={{ width: '100%' }}
                allowClear
                showTime={{ format: 'HH:mm:ss' }}
                format="YYYY-MM-DD HH:mm:ss"
                disabled={!canEdit}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('comboStations.components.configForm.general.endDate')}
              name={['schedule', 'endDate']}
              rules={[
                {
                  required: true,
                  message: t('validations.required'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const startDate = getFieldValue(['schedule', 'startDate']);
                    if (value && startDate && value > startDate) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('validations.fromTo')));
                  },
                }),
              ]}
              getValueFromEvent={(event) => {
                let value = null;
                if (event != null) {
                  value = zonedTimeToUtc(event, comboStation.timezone).getTime();
                }
                return value;
              }}
              getValueProps={(value) => {
                if (value) {
                  return {
                    value: utcToZonedTime(value, comboStation.timezone),
                  };
                }
                return { value: null };
              }}
            >
              <DatePicker
                style={{ width: '100%' }}
                allowClear
                showTime={{ format: 'HH:mm:ss' }}
                format="YYYY-MM-DD HH:mm:ss"
                disabled={!canEdit}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('comboStations.components.configForm.general.continuous')}
              name={['schedule', 'continuous']}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox
                disabled={!canEdit}
              />
            </Form.Item>
          </Col>
        </Row>
        {days.map((day) => renderDay(day))}
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  comboStation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    timezone: PropTypes.string,
  }).isRequired,
};

GeneralConfigFields.propTypes = propTypes;

export default GeneralConfigFields;
