/* eslint-disable no-await-in-loop */
/* eslint-disable no-underscore-dangle */
class SerialPort {
  static isAvailable() {
    return 'serial' in navigator;
  }

  static async connect(portConfig) {
    const port = await navigator.serial.requestPort();

    await port.open(portConfig);

    return port;
  }

  constructor(port) {
    this._port = port;
  }

  async disconnect() {
    await this._port.close();
  }

  async writeStringWithResponseString(str, timeout, validator) {
    const textEncoder = new TextEncoder();
    const toWrite = textEncoder.encode(str);

    const writer = this._port.writable.getWriter();
    await writer.write(toWrite);
    writer.releaseLock();
    const result = await this.read(timeout, (data) => {
      const decoder = new TextDecoder('utf-8');
      const parsed = decoder.decode(new Uint8Array(data)).replace(/[^\x20-\x7E]/g, '');
      return validator(parsed);
    });
    return result;
  }

  async writeStringWithResponse(str, timeout, validator) {
    const textEncoder = new TextEncoder();
    const toWrite = textEncoder.encode(str);

    const writer = this._port.writable.getWriter();
    await writer.write(toWrite);
    writer.releaseLock();
    const result = await this.read(timeout, validator);
    return result;
  }

  async read(timeout, validator) {
    const endDate = new Date();
    endDate.setMilliseconds(endDate.getMilliseconds() + timeout);
    let continueRead = true;
    const buffer = [];
    let result = null;

    const reader = this._port.readable.getReader();

    setTimeout(() => {
      if (continueRead) {
        reader.cancel();
      }
    }, timeout);
    try {
      while (continueRead) {
        const { value, done } = await reader.read();
        if (done) {
          continueRead = false;
        }
        if (value) {
          buffer.push(...value);
          const validationResult = validator(buffer);
          if (validationResult != null) {
            continueRead = false;
            result = validationResult;
          }
        }
      }
      const now = new Date();
      if (now > endDate) {
        continueRead = false;
      }
      if (!continueRead) {
        reader.releaseLock();
      }
    }
    catch (error) {
      reader.releaseLock();
      console.log(error);
    }
    return result;
  }
}

export default SerialPort;
