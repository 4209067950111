import {
  PARAMETERS as SOUND_METER_PARAMETERS,
  SPECTRE_AVERAGE_TYPES as SOUND_METER_SPECTRE_AVERAGE_TYPES,
  SPECTRE_TYPES as SOUND_METER_SPECTRE_TYPES,
} from '@deltaohm/enums/soundMeter';

export const AVAILABLE_MODELS = {
  HD2010UC: 'HD2010UC',
  HD2010UC_A: 'HD2010UC/A',
  HD2110L: 'HD2110L',
};

export const INPUT_GAINS = {
  GAIN_0dB: 0,
  GAIN_10dB: 10,
  GAIN_20dB: 20,
  GAIN_30dB: 30,
  GAIN_40dB: 40,
};

export const PARAMETERS = {
  [SOUND_METER_PARAMETERS.LPK]: 'Lpk',
  [SOUND_METER_PARAMETERS.LPKMX]: 'Lpkmx',
  [SOUND_METER_PARAMETERS.LEQS]: 'LeqS',
  [SOUND_METER_PARAMETERS.LEQ]: 'Leq',
  [SOUND_METER_PARAMETERS.LSP]: 'LSp',
  [SOUND_METER_PARAMETERS.LFP]: 'LFp',
  [SOUND_METER_PARAMETERS.LIP]: 'LIp',
  [SOUND_METER_PARAMETERS.LFMX]: 'LFmx',
  [SOUND_METER_PARAMETERS.LSMX]: 'LSmx',
  [SOUND_METER_PARAMETERS.LIMX]: 'LImx',

  [SOUND_METER_PARAMETERS.LFMN]: 'LFmn',
  [SOUND_METER_PARAMETERS.LSMN]: 'LSmn',
  [SOUND_METER_PARAMETERS.LIMN]: 'LImn',
  [SOUND_METER_PARAMETERS.MEAN]: 'Mean',
  [SOUND_METER_PARAMETERS.SDEV]: 'SDev',
  [SOUND_METER_PARAMETERS.LEQI]: 'LeqI',
  [SOUND_METER_PARAMETERS.LE]: 'LE',
  [SOUND_METER_PARAMETERS.LEP_D]: 'Lep,d',
  [SOUND_METER_PARAMETERS.DOSE]: 'Dose',
  [SOUND_METER_PARAMETERS.DOSE_D]: 'Dose,d',

  [SOUND_METER_PARAMETERS.L1]: 'L1',
  [SOUND_METER_PARAMETERS.L2]: 'L2',
  [SOUND_METER_PARAMETERS.L3]: 'L3',
  [SOUND_METER_PARAMETERS.L4]: 'L4',
  [SOUND_METER_PARAMETERS.OL]: 'OL',
  [SOUND_METER_PARAMETERS.UR]: 'UR',
};

export const SPECTRE_TYPES = {
  [SOUND_METER_SPECTRE_TYPES.AVERAGE]: 'AVERAGE',
  [SOUND_METER_SPECTRE_TYPES.MULTISPECTRE]: 'MULTISP',
  [SOUND_METER_SPECTRE_TYPES.MAXIMUM]: 'MAXIMUM',
  [SOUND_METER_SPECTRE_TYPES.MINIMUM]: 'MINIMUM',
};

export const SPECTRE_AVERAGE_TYPES = {
  [SOUND_METER_SPECTRE_AVERAGE_TYPES.LINEAR]: 'LIN',
  [SOUND_METER_SPECTRE_AVERAGE_TYPES.EXPONENTIAL]: 'EXP',
};
